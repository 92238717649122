@import "@jobandtalent/design-system/dist/assets/less/colors";

.client-demand-info-tooltip {
  cursor: help;

  .icon {
    color: @dark_alpha80;
  }
}

.client-demand-info-tooltip-explanation {
  list-style-type: disc;
  margin-left: 10px;

  li {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.client-demand-table-wrapper {
  position: relative;
  overflow: auto;
}

.client-demand-table {
  table-layout: auto;

  .sticky-column {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  .info-tooltip {
    margin: 0 20px 20px;
  }

  .entry-time {
    display: block;
    color: @dark_alpha60;
    font-size: 10px;
    font-weight: 600;
    line-height: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  tr {
    height: auto;
    box-shadow: none !important;

    &:hover {
      box-shadow: none !important;
    }

    &:not(.title-row):hover .sticky-column {
      background-color: rgb(218, 236, 254);
    }
  }

  th {
    line-height: normal;
    vertical-align: middle;
    text-align: center;
    height: 3rem;
    padding: 0 5px;
    min-width: 126px;

    &:first-child {
      background-color: rgb(250, 250, 250);
      padding-right: 20px;
      box-shadow: inset -1px 0 @dark_alpha40, 0 2px 8px 0 @black_alpha10;
      border-right: none;
      width: 210px;
      min-width: 210px;
    }
  }

  .demand-header-button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    color: inherit;
    text-transform: uppercase;
    font-weight: 600;

    &:hover {
      color: @dark;
    }
  }

  td {
    line-height: initial;
    vertical-align: middle;
    text-align: left;
    padding: 5px 15px;
    font-size: 14px;
    border-right: 1px solid @black_alpha10;
    min-width: 105px;

    &:first-child {
      font-size: inherit;
      background-color: @white;
      box-shadow: inset -1px 0 @dark_alpha40, 0 2px 8px 0 @black_alpha10;
      border-right: none;
      max-width: 230px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .delete-daily-record {
        display: none;
        height: 20px;
        width: 20px;
        color: @red_alpha80;
        border: none;
        background: none;
        cursor: pointer;

        .ic-delete {
          height: 20px;
          width: 20px;
        }

        &:hover {
          color: @red;
        }
      }

      &:hover .delete-daily-record {
        display: block;
      }
    }
  }

  .weekend {
    background-color: @yellow_alpha08;
  }

  .missing-bookings {
    background-color: @red;
    color: @white;
  }

  .overbooked {
    background-color: @dark_alpha80;
    color: @white;
  }

  .requested-workers-selector {
    display: inline-block;
    vertical-align: middle;
    min-width: 40px;
    max-width: 60px;
    width: 40%;
    margin-right: 5px;
    position: relative;

    input {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .requested-workers-selector-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -8px;
    margin-top: -8px;
    width: 17px;
    height: 17px;
  }
}

.client-demand-totals {
  margin-top: 40px;

  .title-row {
    font-size: 20px;
    border-bottom: 1px solid @black_alpha10;
  }

  tr.hidden {
    display: none;
  }

  th:not(:first-child) {
    font-size: 14px;
  }

  td:not(.sticky-column) {
    text-align: center;
  }

  .toggle-totals-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    width: 100%;

    .icon {
      width: 18px;
      height: 18px;
      transform: rotate(90deg);
      vertical-align: middle;
    }

    &.shown .icon {
      transform: rotate(-90deg);
    }

    &:hover {
      color: @primary_blue_alpha80;
    }
  }
}

.client-demand-weekly-link {
  color: inherit;
  display: inline-block;
  min-width: 10px;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
}

.compare-demand-modal {
  position: relative;
  padding-top: 20px;
  min-width: 200px;
  min-height: 200px;

  .dm_modal_close {
    top: 0;
    right: 0;
    z-index: 11;
  }

  .client-demand-table {
    border: 1px solid @black_alpha10;

    tr:nth-child(1) th:first-child {
      padding-left: 30px;
    }

    tr:nth-child(2) th {
      background: #EFEFEF;
      width: auto;
      text-align: center;
    }

    th,
    td {
      box-shadow: none;
      min-width: 30px;
      white-space: nowrap;

      &.with-right-border {
        border-right: 1px solid @black_alpha10;
      }
    }

    th {
      padding: 0 10px;
    }

    td:not(:first-child) {
      text-align: center;
    }
  }
}

.dm_dc_charts_wrapper {
  .clearfix();
  margin-bottom: 20px;
}

.dm_dc_chart {
  width: 13.4%;
  float: left;
  margin-right: 1%;

  &:nth-child(7n+0) {
    margin-right: 0;
  }
}

.dm_dc_waypoint_title {
  font-size: 18px;
  margin-top: 25px;
}

.dm_demand_curves {
  .dm_dc_continue_form {
    margin-left: 20px;
    display: inline-block;
  }
}

.dm_dc_errors_title {
  .ds-text-subtitle-base-semibold();
  color: @red;
  margin-bottom: 10px;
}

.dm_dc_errors {
  max-height: 400px;
  overflow-y: scroll;
}

.dm_dc_error {
  margin-bottom: 5px;
  color: @red;
}

.dm_workload_container {
  min-height: calc(100vh - 235px);
  width: 50%;

  .dm_workload_subtitle {
    color: @dark_alpha60;
    margin-bottom: 20px;
  }

  .dm_workload_explanation{
    font-size: (16 / @px);
    line-height: 1.25;
    margin-bottom: 20px;
  }
}

.dm_workload_preferences_container {
  display: flex;
  justify-content: space-between;
}

.dm_workload_preference_container {
  display: inline-block;
  margin-bottom: (44 / @px);
}

.dm_workload_preference_title {
  font-size: (20 / @px);
  line-height: 1.4;
  margin-bottom: (20 / @px);
}

.dm_workload_preference_input_label {
  .ds-text-body-base-semibold();
  display: block;
}

.dm_workload_preference_input {
  max-width: (120 / @px);
}

.dm_workload_preference_confirmed {
  margin: (16 / @px) 0;
}

.dm_workload_footer {
  bottom: (40 / @px);
  margin-bottom: (20 / @px);
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;

  .dm_workload_action_buttons_container {
    position: absolute;
    right: (20 / @px);
  }

  .dm_workload_button {
    color: @primary_blue;
    height: (40 / @px);
    left: (20 / @px);
    padding: 0;
    position: absolute;
    text-transform: none;

    .dm_workload_button_back_text {
      display: inline;
      line-height: (17 / @px);
    }

    .dm_workload_icon_chevron {
      height: (12 / @px);
      width: (12 / @px);
      transform: rotate(180deg);
    }
  }

  .dm_schedules_action {
    margin-left: (20 / @px);
  }
}


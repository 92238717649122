@import "@jobandtalent/design-system/dist/assets/less/colors";
@import "@jobandtalent/design-system/dist/assets/less/mixins";

.clients_table {
  .client_edit {
    cursor: pointer;

    svg {
      height: 14px;
      color: @black_alpha20;
    }

    &:hover svg {
      color: @primary_blue;
    }
  }

  th {
    height: 52px;
    box-shadow: inset 0 -1px 0 0 @dark_alpha10;
    background-color: #fbfbfb;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.2;
    color: @dark_alpha60;
    vertical-align: middle;
    text-transform: uppercase;
    padding: 0 20px;
  }

  tr {
    cursor: pointer;

    &:hover {
      background-color: @primary_blue_alpha40;
    }

    td {
      border-right: 0px;

      &.name {
        strong {
          color: @primary_blue
        }
      }
    }
  }

  th.edit, .edit_column{
    width:69px;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .table-link {
    color: @dark;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .account-link {
    font-size: 10px;
    line-height: 1.4;
    color: @dark_alpha60;
    border: none;
    background-color: transparent;
    padding: 0;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.dm_container.for-clients {
  margin-bottom: 20px;
  padding: 20px 0;
}

.dm_header_actions_container.unpadded.clients_header {
  padding: 0 20px;
}

.dm_right_side_panel {
  position: fixed;
  z-index: 6;
  right: 0px;
  height: 100%;
  top: 0;
  transition: right 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 460px;
  background-color: @white;
  -webkit-box-shadow: -2px 0 8px 0 @dark_alpha10;
  box-shadow: -2px 0 8px 0 @dark_alpha10;

  form {
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
  }

  &.no-show, &.transition-enter, &.transition-leave {
    right: -460px;
  }

  &.show, &.transition-enter-active {
    right: 0px;
  }

  &.wide {
    width: 520px;

    &.no-show, &.transition-enter, &.transition-leave {
      right: -520px;
    }
  }

  &.for_log {
    width: 480px;

    &.no-show, &.transition-enter, &.transition-leave {
      right: -480px;
    }
  }

  .dm_right_side_panel_header {
    .ds-text-title-base-regular();
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0 -1px 0 0 @dark_alpha10;
    padding: 0 20px;
  }

  .dm_right_side_panel_content {
    height: 100%;
    flex: 1;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;

    .dm_right_side_panel_form {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;

      .dm_right_side_panel_form_fields {
        flex: 1;
        overflow: auto;
      }
    }
  }

  .dm_right_side_panel_footer {
    background-color: @white;
    box-shadow: 0 -2px 8px 0 @black_alpha05, inset 0 1px 0 0 @dark_alpha10;
    flex-basis: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 20px;
  }

  .cross-svg {
    width: 9px;
    height: 9px;

    use {
      color: @dark_alpha40;
    }

    &:hover {
      cursor: pointer;

      use {
        color: @dark_alpha80;
      }
    }
  }

  .choices__inner {
    width: 388px !important;
  }
}

.dm_sidebar_delete_link {
  color: @dark_alpha40;
  text-decoration: none;
  order: 1;

  svg {
    vertical-align: sub;
  }

  use {
    color: @red;
  }

  &:hover {
    color: @red;
  }
}

.dm_sidebar_mask {
  background-color: @dark_alpha40;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: none;

  &.show {
    display: block;
  }
}

.dm_right_side_section {
  position: relative;
}

.dm_right_side_content_toggler {
  .ds-text-body-big-semibold();
  line-height: 48px;
  position: relative;
  cursor: pointer;

  .dm_icon {
    display: inline-block;
    margin-right: 7px;
    position: relative;
    top: 2px;
    width: 16px;
  }

  &:before {
    content: "";
    display: inline-block;
    left: 0;
    width: 0;
    height: 0;
    border: solid transparent;
    border-left-color: @dark;
    border-width: 6px;
    margin-right: 10px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 55px;
    bottom: 0;
    right: 0;
    background-color: @black_alpha10;
    height: 1px;
  }

  &.shown:before {
    border-color: transparent;
    border-top-color: @dark;
    vertical-align: sub;
  }
}

.dm_right_side_toggleable_content {
  padding-left: 55px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: none;

  &.shown {
    display: block;
  }
}

// Header actions
.dm_header_actions_container {
  .clearfix();
  padding: 30px 30px 0;
  margin-bottom: 20px;

  &.unpadded {
    padding: 0;
  }
}

.dm_header_right_actions {
  float: right;
}

.dm_header_action_wrapper {
  position: relative;
  display: inline-block;
}

.dm_header_action {
  display: inline-block;
  margin-right: 10px;
  max-width: 210px;
  vertical-align: middle;
  white-space: nowrap;

  .dm_icon {
    color: @dark_alpha60;
    vertical-align: sub;
  }

  &:hover .dm_icon {
    color: @primary_blue;
  }

  &.floated {
    float: right;
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.hidden {
    display: none;
  }

  .ds-atom-dialog-cell.selected:after {
    display: none;
  }
}

.dm_clear_header_action {
  position: absolute;
  border: none;
  background-color: transparent;
  right: 3px;
  top: 9px;
  cursor: pointer;

  .cross-svg {
    width: 9px;
    height: 9px;
    color: @dark_alpha60;
  }

  &:hover .cross-svg {
    color: @dark;
  }
}

.dm_header_action_button {
  box-shadow: 0 2px 4px @black_alpha05;
  max-width: 156px;
  overflow: hidden;
  text-overflow: ellipsis;
}

button.dm_header_action {
  padding: 0 22px;
}

button.dm_header_action_button {

  &:hover {
    background-color: transparent;
  }

  .dm_icon {

    &.selected {
      color: @primary_blue;
    }
  }
}

.dm_fake_icon {
  height: (15 / @px);
  width: (15 / @px);
}

.dm_drop_div {
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: dashed 5px rgba(28, 151, 245, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dm_modal_text_container {
  margin-top: 30px;
  font-size: 30px;
  font-weight: 600;
  line-height: 0.83;
  text-align: center;
  color: rgba(35, 40, 46, 0.8);
}

.dm_drop_image {
  width: 172px;
  height: 142px;
}

// Modal
.dm_modal_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: @black_alpha20;
  cursor: pointer;

  .generate_modals(11);

  .generate_modals(@i) when (@i > 0) {
    &.dm_modal_@{i} {
      z-index: @i + 1;
    }

    .generate_modals(@i - 1);
  }
}

.dm_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: @white;
  border-radius: 6px;
  box-shadow: 0 2px 8px @black_alpha10;
  cursor: default;
  z-index: 4;

  .dm_modal_container+.dm_modal_container {
    margin-top: 40px;
  }

  .dm_modal_header_container {
    margin-bottom: 0;
    padding: 30px 0 18px 20px;
    position: sticky;
    top: 0;
    z-index: 4;
  }

  .dm_modal_header {
    .ds-text-title-base-semibold();
    margin: 0 20px 5px;

    &.with_inline_subtitle {
      display: inline-block;
      margin: 0 16px 20px 0;
    }

    &+.dm_modal_subtitle {
      display: inline-block;
    }

    &.without_subtitle {
      margin-bottom: 20px;
    }

    &.with_subtitle {
      margin-top: 10px;
    }

    &.without_left_margin {
      margin-left: 0;
    }
  }

  .dm_modal_subheader {
    .ds-text-body-base-regular();
    margin-bottom: 20px;
  }

  .dm_daily_scheduler_head {
    background-color: white;
    position: fixed;
    width: calc(100% - 40px);
    z-index: 3;
  }

  .dm_daily_scheduler_body {
    .clearfix();
    margin-top: 34px;
    position: relative;
  }

  .generate_modals(11);

  .generate_modals(@i) when (@i > 0) {
    &.dm_modal_@{i} {
      z-index: @i + 1;
    }

    .generate_modals(@i - 1);
  }
}

.dm_modal_small {
  background-color: @white;
  color: @dark;
  left: 50%;
  padding: 20px;
  position: fixed;
  text-align: left;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  white-space: pre-line;
  width: 440px;

  .dm_modal_header {
    .ds-text-title-base-semibold();
    font-size: 24px;
    margin-bottom: 5px;
  }

  .dm_modal_text {
    .ds-text-title-base-regular();
    border-bottom: 1px solid @dark_alpha40;
    font-size: 14px;
    line-height: 1.35;
    padding-bottom: 20px;
  }

  .dm_modal_buttons {
    float: right;
    margin-top: 20px;

    .dm_right_button {
      margin-left: 20px;
    }
  }
}

.dm_modal_close {
  position: absolute;
  right: 10px;
  top: -10px;
  border: none;
  cursor: pointer;
  z-index: 5;
  padding: 0;
  background-color: transparent;

  &:hover .dm_modal_close_icon {
    color: @dark;
  }

  &:focus {
    outline: none;
  }
}

.dm_modal_sidebar {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 30px;
  background-color: @bg_grey;
  border-right: 1px solid @black_alpha10;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 220px;
  box-sizing: border-box;
  z-index: 1;
}

.dm_icon_list_error,
.dm_icon_list_ok {
  width: 65px;
  height: 80px;
}

.dm_modal_import_result_title {
  .ds-text-body-big-semibold();
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dm_modal_import_result_text {
  .ds-text-footnote-regular();
  display: inline-block;
  color: @dark_alpha60;
  margin-bottom: 5px;
}

.dm_modal_import_content {
  height: 280px;
  margin: 0 20px 5px;
  padding-top: 10px;
  overflow: auto;
}

.dm_modal_import_title {
  width: 30%;
  clear: left;
  float: left;
  margin-bottom: 10px;
  opacity: 0.8;
  font-size: 12px;
  line-height: 1.17;
  color: @dark_alpha80;
}

.dm_modal_import_text {
  width: 65%;
  float: left;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1;
  color: @dark;
}

.dm_modal_import_line_error {
  line-height: 1.5;
  margin-bottom: 10px;
}

.dm_modal_avatar {
  .ds-text-display-small();
  border-radius: 100%;
  width: 80px;
  height: 80px;
  background-color: @dark_alpha10;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
  line-height: 80px;
  letter-spacing: -4px;
}

.dm_modal_worker_name {
  .ds-text-body-big-semibold();
  font-size: 16px;
  font-weight: 600;
  color: @primary_blue;
  display: inline-block;
  margin-bottom: 10px;
}

.dm_modal_bulk_description {
  .ds-text-body-big-semibold();
  font-size: 16px;
  font-weight: 600;
  color: @primary_blue;
  display: inline-block;
  margin-bottom: 37px;
}

.dm_modal_worker_telephone {
  .ds-text-footnote-regular();
  color: @dark_alpha60;
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
}

.dm_modal_worker_telephone_link {
  color: @dark_alpha60;
}

.dm_modal_worker_employee {
  .ds-text-footnote-regular();
  display: inline-block;
  color: @dark_alpha60;
  margin-bottom: 37px;
}

.dm_variable_modal_content {
  padding: 20px;

  &.dm_shifts_modal_content {
    padding: 0 20px 50px;
  }

  .dm_daily_scheduler_wrapper {
    margin-top: 0;
  }
}

.dm_modal_content {
  margin: 20px 0 20px 220px;
  position: relative;
  height: 540px;
  width: 525px;
  box-sizing: border-box;

  &.without_sidebar {
    margin-left: 0;
    height: auto;
  }

  &.import_results {
    height: 357px;
  }

  &.basic {
    height: auto;
    width: auto;
    margin: 20px;
  }

  .modal-explanation {
    max-width: 400px;
    font-size: 16px;
    line-height: 18px;
  }

  &.for-conflicts {
    margin: 20px
  }

  @media only screen and (max-height: 650px) {
    height: 65vh;
  }
}

.dm_modal_footer {
  /* this should be delete when old daily view disapear as is only used in modals of this view */
  border-top: 1px solid @black_alpha10;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  text-align: right;
  box-shadow: 0 -2px 8px @black_alpha05;
  background-color: @white;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;

  button {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }

  &.not_fixed {
    position: relative;
  }
}

.dm_new_modal_footer {
  border-top: 1px solid @black_alpha10;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px 0;
  margin: 0 20px;
  text-align: right;
  box-shadow: 0 -2px 0 @black_alpha05;
  background-color: @white;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  z-index: 2;

  button {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }

  &.not_fixed {
    position: relative;
  }
}

.dm_modal_select_box {
  margin-top: 7px;

  .Select-input {
    padding: 0;
  }
}

.dm_modal_shift_selector_content {
  margin: 0px 0 20px 0px;
  position: relative;
  width: 525px;
  box-sizing: border-box;
  max-height: 450px;
  min-height: 200px;
  overflow: auto;
}

.dm_header_action {
  .ds-atom-dialog-cell.for-check {
    padding: 8px 35px 8px 35px;
    width: inherit;

    &.multiple {
      min-width: 150px;
    }
  }
}

.dm_disabled_drop_down_cell {
  opacity: .5;

  &:hover {
    background-color: transparent;
  }

  button {
    cursor: not-allowed;
    color: @dark_alpha40;

    &:hover {
      background-color: transparent;
    }
  }
}

.dm_shifts_modal {
  max-height: 582px;
  min-height: 377px;

  .dm_daily_scheduler_body {
    max-height: 365px;
    overflow-y: auto;
  }

  .dm_modal_close {
    top: 10px;
  }
}

.dm_modal_content {

  form.dm_daily_schedule_select_wrapper {
    height: 515px;
    overflow-y: auto;
    padding: 0 20px;

    @media only screen and (max-height: 650px) {
      height: 60vh;
    }
  }

  .dm_daily_schedule_select_wrapper {
    display: block;

    .Select,
    .Select-container {
      margin-top: (5 / 16rem);

      &.error {
        float: none;
      }
    }

    .Select-control {
      z-index: 2;

      input {
        margin-top: 0;
      }
    }

    .Select-menu-outer {
      z-index: 3;
    }
  }
}

.ds-mol-input-field {
  &.disabled {
    color: @dark_alpha40;
    cursor: not-allowed;
  }
}

// Daily scheduler shared styles
.dm_daily_scheduler_wrapper {
  .clearfix();
  min-width: 840px;
}

.dm_daily_scheduler_head {
  .clearfix();
  min-height: 42px;

  &:before {
    content: "";
    bottom: 0;
    box-shadow: 0 2px 8px @black_alpha10;
    height: 8px;
    position: absolute;
    width: 100%;
  }
}

.dm_daily_scheduler_head_element {
  font-size: 12px;
  line-height: 35px;
  color: @dark_alpha80;
  padding-bottom: 5px;
  width: calc((100% - 10px) / 26);
  float: left;

  &.hightlighted {
    border-bottom: 2px solid @primary_blue;
    box-sizing: border-box;
  }

  &.hightlighted+.crossing_day {
    border-bottom: 2px solid @primary_blue;
    box-sizing: border-box;
  }

  &.crossing_day {
    line-height: 36px;
    width: 5px;
  }

  &.first_cell {
    width: 190px;
  }

  .dm_daily_scheduler_head_number {
    display: block;
    font-size: 18px;
    line-height: 20px;
    margin-left: -50%;
    padding-top: 5px;
    text-align: center;
    width: 100%;

    &.other_day {
      opacity: .6;
    }
  }

  .dm_daily_scheduler_head_text {
    display: block;
    font-size: 10px;
    line-height: 10px;
    margin-left: -50%;
    text-align: center;
    white-space: nowrap;
    width: 100%;

    &.other_day {
      opacity: .6;
    }
  }
}

.dm_daily_scheduler_body {
  .clearfix();
  position: relative;
}

.dm_daily_scheduler_body_element {
  box-sizing: border-box;
  border: 1px solid @black_alpha05;
  border-width: 0 0 0 1px;
  position: relative;
  width: calc((100% - 10px) / 26);
  float: left;
  min-height: 156px;

  &.crossing_day {
    width: 5px;
    background-color: @yellow_alpha40;
    opacity: .6;

    &:before {
      display: none;
    }
  }

  &.previous_day,
  &.next_day {
    background: rgba(0, 0, 0, 0.05);
    background-image: radial-gradient(@black_alpha05 20%, transparent 0),
      radial-gradient(@black_alpha05 20%, transparent 0);
    background-size: 10px 10px;
    background-position: 0 0, 5px 5px;
    border: 0;
    opacity: .6;
  }

  &:before {
    box-sizing: border-box;
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 1px;
    border-right: 1px dashed @black_alpha05;
    transform: translateX(-50%);
  }
}

.dm_daily_scheduler_shifts_wrapper {
  position: absolute;
  top: 0;
  left: calc((100% / 26) + 5px);
  right: calc((100% / 26) + 5px);
  bottom: 0;
}

.dm_daily_scheduler_dragging_row_highlight {
  position: absolute;
  width: 100%;
  pointer-events: none;
  border: 1px solid @primary_blue_alpha40;
  border-radius: 6px;
  background-color: @primary_blue_alpha08;
  height: 44px;
}

.dm_daily_scheduler_dragging_highlight {
  background-color: @primary_blue;
  bottom: 0;
  height: 2px;
  position: absolute;
  z-index: 3;
}

.dm_daily_scheduler_shifts_by_waypoint_wrapper {
  box-sizing: border-box;
  min-height: 72px;
  padding: 10px 0 20px 0;
  position: relative;

  &:nth-child(even) {
    background-color: @dark_alpha10;
  }

  .dm_daily_scheduler_shifts_waypoint_title {
    box-shadow: 0 4px 8px @black_alpha10;
    color: @white;
    font-size: 10px;
    margin-left: -20px;
    position: sticky;
    text-transform: uppercase;
    top: 20px;
    z-index: 2;
  }
}

.dm_shift_wrapper {
  position: relative;

  .dm_ds_resting_time:hover+.dm_ds_resting_time_popover {
    display: block;
    position: fixed;
    z-index: 7;
  }
}

.dm_daily_scheduler_shift {
  .ds-text-body-base-semibold();
  margin-top: 10px;
  box-sizing: border-box;
  height: 30px;
  line-height: 30px;
  z-index: 1;
  position: relative;
  text-align: left;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: @neutral_light;
  color: @neutral;
  border-left-color: @neutral;
  padding: 0 25px 0 10px;
  border: 0;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0 1px 2px 0 @black_alpha05;
  border: solid 1px @dark_alpha10;

  &:focus,
  &:hover {
    box-shadow: 0 4px 15px 0 @dark_alpha10, inset 4px 0 0 0 @dark_alpha10;
    outline: none;
    cursor: pointer;
  }

  &:hover {
    .dm_daily_scheduler_edit_shift_icon {
      display: block;
    }
  }

  &.crossing_day {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAJElEQVQYV2P8////f0ZGRkYGJADmoEvAVSBLoGiDSaAIwowCABuWFAJzz3SVAAAAAElFTkSuQmCC);
    background-repeat: repeat;
    background-color: mix(@white, @neutral, 80%);
  }

  &.ends_tomorrow {
    border-radius: 0;
  }

  &.starts_yesterday {
    &:hover {
      cursor: auto;

      .dm_daily_scheduler_edit_shift_icon {
        display: none;
      }
    }

    &:before {
      display: none;
    }
  }

  &.dragging {
    box-shadow: 0 4px 15px 0 @dark_alpha10, inset 4px 0 0 0 @dark_alpha10;
    outline: none;
  }

  &:before {
    content: "";
    background-color: @dark;
    top: 0;
    left: 0;
    height: 30px;
    position: absolute;
    width: 4px;
    z-index: 10;
  }

  &.allocated,
  &.pending {
    background-color: @white;
    color: @pending;

    &:before {
      background-color: @pending;
    }

    &.crossing_day {
      background-color: mix(@white, @pending, 80%);
    }

    .dm_daily_scheduler_edit_shift_icon {
      color: @primary_blue;

      &:hover {
        color: @primary_blue;
      }
    }
  }

  &.proposed {
    background-color: @white;
    color: @proposed;

    &:before {
      background-color: @proposed;
    }

    &.crossing_day {
      background-color: mix(@white, @proposed, 80%);
    }

    .dm_daily_scheduler_edit_shift_icon {
      color: @yellow;

      &:hover {
        color: @yellow;
      }
    }
  }

  &.accepted {
    background-color: @white;
    color: @accepted;

    &:before {
      background-color: @accepted;
    }

    &.crossing_day {
      background-color: mix(@white, @accepted, 80%);
    }

    .dm_daily_scheduler_edit_shift_icon {
      color: @green;

      &:hover {
        color: @green;
      }
    }
  }

  &.rejected {
    background-color: @white;
    color: @rejected;

    &:before {
      background-color: @rejected;
    }

    &.crossing_day {
      background-color: mix(@white, @rejected, 80%);
    }

    .dm_daily_scheduler_edit_shift_icon {
      color: @red;

      &:hover {
        color: @red;
      }
    }
  }

  &.draggable,
  &.dragging {
    .dm_icon.draggable {
      display: inline-block;
      height: 10px;
      width: 10px;
      margin-right: 4px;
      margin-left: -1px;
    }

    &:before {
      cursor: ew-resize;
    }

    &:after {
      content: "";
      cursor: ew-resize;
      top: 0;
      right: 0;
      height: 30px;
      position: absolute;
      width: 4px;
      z-index: 10;
    }
  }

  &.resizing {
    &:hover {
      .dm_daily_scheduler_edit_shift_icon {
        display: none;
      }
    }
  }

  .dm_daily_scheduler_edit_shift_icon {
    color: @dark_alpha40;
    z-index: 5;

    &:hover {
      color: @dark_alpha60;
    }
  }

  .dm_icon.draggable {
    display: none;
  }
}

.dm_daily_scheduler_edit_shift_icon {
  display: none;
  position: absolute;
  right: 10px;
  bottom: 8px;
}

// Day picker
.DayPicker-wrapper {
  .DayPicker-Caption div {
    .ds-text-body-base-semibold();
    text-align: center;
    text-transform: capitalize;
  }

  .DayPicker-Weekday {
    text-transform: capitalize;
  }

  .dm_arrow {
    background-color: transparent;
    border: none;
    line-height: 17px;
    margin: 15px;
    padding: 0;

    &:hover,
    &:focus {
      outline: none;
    }

    .arrow {
      fill: @dark_alpha40;
      vertical-align: middle;
    }
  }

  .dm_daypicker_arrows {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .dm_arrow_left {
    .dm_arrow();
    color: @dark_alpha40;
    cursor: pointer;
    float: left;

  }

  .dm_arrow_right {
    .dm_arrow();
    color: @dark_alpha40;
    cursor: pointer;
    float: right;
    transform: rotate(180deg)
  }
}

// Month picker
.month_picker_container {
  border: 1px solid #dddddd;
  border-radius: 5px;
  position: absolute;
  width: 265px;
}

.month_picker {
  box-sizing: border-box;
  display: block;
  overflow: auto;
  position: relative;
  white-space: normal;
  width: 100%;
}

.month_picker_month {
  cursor: pointer;
  display: inline-block;
  transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
  outline: none;
  padding: 15px 0px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 33.3%;

  &:hover {
    background-color: @primary_blue_alpha08;
  }

  &.selected {
    background-color: @primary_blue;
    color: @white;
  }
}

.month_picker_year {
  .ds-text-body-base-semibold();
  display: inline-block;
  left: 50%;
  line-height: 17px;
  margin-bottom: 0.5rem;
  margin-top: 15px;
  position: absolute;
  text-align: left;
  transform: translateX(-50%);
}

.worker_selector {
  width: 100%;
  padding-top: 5px;

  .Select__container__control--is-disabled {
    opacity: .3;
  }

  .Select__container__control {
    min-height: 36px;
  }

  .Select__container__indicator {
    .icon {
      width: 10px;
      height: 10px;
    }
  }

  .Select__container__clear-indicator {
    padding-right: 0px;
  }

  .worker_cell {
    width: 100%;
    padding: 10px;
    position: relative;
    border: 0px;
    background-color: @white;

    &.serity_hard {
      opacity: .4;
    }

    .worker_info {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      text-align: left;
      color: @dark;
    }

    .ds-atom-dialog-cell-second-line {
      display: flex;
      align-items: center;

      svg.workforce_leaves_kind_icon {
        color: #65a50ee6;
        height: 14px;
        width: 14px;
        margin-right: 10px;
        vertical-align: middle;

        &.heart {
          color: #e55353;
          ;
        }

        &.band_aid {
          color: #ffa800;
        }

        &.plain,
        &.ring,
        &.book,
        &.clock-filled {
          color: #1c97f5;
        }

        &.briefcase {
          color: rgba(165, 100, 14, 0.9);
        }

        &.feeder {
          color: rgba(136, 14, 165, 0.9);
        }

        &.truck {
          color: #b76f00;
        }
      }
    }

    &:hover,
    &.selected {
      background-color: @primary_blue_alpha08;
    }
  }
}

.dm_input_modal_tooltip {

  &.icon {
    .dm_icon.info();
    vertical-align: top;
  }
}

.dm_input_modal_tooltip.ds-tooltip {
  color: @white;
  white-space: normal;
  width: 266px;
  z-index: 6;
}

.dm_tooltip_info {
  .ds-text-body-base-semibold();
  background-color: @dark;
  border-radius: 5px;
  color: @white;
  max-width: 266px;
  padding: 15px;
  position: absolute;
  white-space: nowrap;
  z-index: 3;
  left: 27px;
  top: 40px;

  &:before {
    top: -15px;
    left: 13px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    border-color: transparent;
    border-bottom-color: #23282e;
    border-width: 8px;
  }
}

.ds-tooltip {
  &>button {
    background: none;
    border: none;
    padding: 0;
    color: inherit;
  }

  .ds-tooltip-header {
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.dm_icon.info {
  color: @dark_alpha40;
  margin-left: 10px;
  vertical-align: sub;

  &:hover+.dm_tooltip_info {
    display: block;
  }
}

.dm_sticky_container.stuck {
  padding-top: 57px;
}

.dm_sticky_header.stuck {
  background-color: @white;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .1);
  position: fixed;
  top: 0;
  width: calc(100% - 122px);
  z-index: 2;
  padding: 20px 0 20px;
  min-width: 838px;

  .dm_ds_action_buttons {
    right: 0;
  }
}

button.dm_delete_button {
  height: 14px;
  line-height: 14px;
  padding: 0;
  position: absolute;
  right: 2px;
  top: 3px;
  width: 14px;

  &:hover {
    background-color: transparent;
  }

  .icon {
    color: @dark_alpha40;
    height: 14px;
    width: 13px;

    &:hover {
      color: @red;
    }
  }
}

.dm_button_resend {
  color: @primary_blue;
  float: right;
  font-size: 14px;
  font-weight: 600;
  margin-top: 25px;
  text-transform: initial;
}

.dm_modal_waypoint_warning {
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: @primary_blue_alpha08;

  .dm_modal_waypoint_warning_content {
    margin-left: 10px;
  }

  .icon {
    width: 15px;
    height: 15px;
    min-width: 15px;
    margin-left: 10px;
  }
}

.dm_modal_shift_warning {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-radius: 5px;
  margin-bottom: 20px;
  line-height: 1rem;

  &.severity_hard {
    background-color: @red_alpha08;
  }

  &.severity_soft {
    background-color: @yellow_alpha08;
  }

  .icon {
    width: 15px;
    height: 15px;
    min-width: 15px;
    margin-left: 10px;
  }

  .dm_modal_shift_warning_content {
    margin: 0 10px 0 10px;

    .dm_name {
      .ds-text-body-base-semibold();
    }
  }

  .shift-warning-button {
    .ds-text-body-base-semibold();
    border: none;
    background: none;
    padding: 0;
    margin: 0 10px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.dm_warning {
  color: @white_alpha80;
  font-size: 14px;
  padding: 20px;
  margin-bottom: 10px;
  width: 400px;
  position: relative;
  border-radius: 20px;
  background-color: @dark;
  line-height: 18px;
  box-shadow: 0 4px 15px 0 @dark_alpha40;
  opacity: 1;
  transition: opacity .2s;

  h3 {
    color: @white;
    margin-bottom: 10px;
  }

  &.error {
    background-color: @rejected;
  }

  &.success {
    background-color: @accepted;
  }

  &.warning {
    background-color: @proposed;
  }

  &.closing {
    opacity: 0;
  }

  &.dm_warning_over_modal.ds-org-snack-bar {
    z-index: 5;
  }
}

.import-status {
  padding: 11px;
  margin-right: 10px;

  .ds-atom-drop-down-cell:hover {
    background-color: @white;
  }

  .refresh {
    font-size: 12px;
  }

  .action-divider {
    margin: 8px 0;
  }

  .ts-progress-bar-dropdown-cell {
    width: 100%;
    min-width: 150px;
    margin: 5px 0;
    cursor: default;

    &.empty-state {
      color: @dark_alpha60;
    }

    .first-line {
      display: flex;
      justify-content: space-between;

      .first-line-type {
        margin-right: 5px
      }
    }

    .bottom-line {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .progress_bar {
      width: 100%;
    }

    .status_text {
      margin-right: 10px;
      flex: 2;
      width: 100%;

      &.status-succeeded {
        color: @green;
      }

      &.status-crashed {
        color: @yellow;
      }

      &.status-waiting_to_run {
        color: @primary_blue;
      }

      &.status-failed {
        color: @red;
      }

      &.status-in_progress {
        color: @primary_blue;
      }

      &.status-pending {
        color: @yellow;
      }

      &.status-waiting_to_run {
        color: @yellow;
      }

      &.status-running {
        color: @primary_blue;
      }
    }

    .status_bar {
      width: 100%;
      flex: 3;
    }
  }
}

.export-data-overlay {
  display: inline-block;
  margin-right: 10px;
}

button.export-data {
  margin-right: 0;
}

.table-filters {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0 20px 10px;
  position: relative;
  z-index: 3;

  .Select__container,
  .filter-input {
    width: 200px;
  }

  .create-button {
    margin-left: auto;
  }
}

.dm_weekly_shifts_table_shift_waypoint,
.rotas-calendar-shift {
  &.blue {
    background-color: #334e70;
  }

  &.bright_blue {
    background-color: #689cb2;
  }

  &.red {
    background-color: #f68989;
  }

  &.dark_red {
    background-color: #a0586b;
  }

  &.green {
    background-color: #00a356;
  }

  &.bright_green {
    background-color: #6bcba4;
  }

  &.beige {
    background-color: #bdac94;
  }
}

.snackbar-reload-button {
  color: @white_alpha80;
  font-weight: 600;
  margin-top: 10px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: @white;
  }
}

.expiration-date-warning {
  color: @red;
  font-weight: 600;

  &.soft {
    color: @yellow;
  }
}

.calendar-conflict-tooltip:not(.ds-tooltip-container) {
  width: 180px;

  &:before {
    left: auto;
    right: 10px;
  }
}

.conflicts-list {
  max-height: 490px;
  overflow-y: auto;
  background:
    linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

.conflicts-list-button {
  display: inline-block;
  margin-top: 10px;
}

.ranged-date-picker {
  .DayPicker-Day--selected {
    background-color: #4A90E2;
    color: #F0F8FF;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
    background-color: #F0F8FF !important;
    color: #4A90E2;
  }

  .DayPicker-Day {
    border-radius: 0 !important;
  }
}

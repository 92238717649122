.dm_weekly_summary_table {
  width: 100%;
  text-align: center;
  border-collapse: separate;
  table-layout: fixed;

  tr:last-child {

    .dm_weekly_summary_table_cell,
    .dm_weekly_summary_table_definition,
    .dm_weekly_summary_table_total {
      border-bottom: 1px solid @black_alpha10;
    }
  }

  .today {
    background-color: @yellow_alpha08;
  }
}

.dm_weekly_summary_table_head,
.dm_weekly_shifts_table_head {
  .ds-text-footnote-bold();
  position: relative;
  line-height: 45px;
  color: @primary_blue;
  background-color: @primary_blue_alpha08;
  border-right: 1px solid @black_alpha10;
  border-top: 1px solid @black_alpha10;

  &:nth-child(4):not(.dm_weekly_shifts_table_head) {
    border-left: 1px solid @black_alpha10;
    border-top-left-radius: 6px;
  }

  &:last-child:not(.dm_weekly_shifts_table_head) {
    border-top-right-radius: 6px;
  }

  .dm_header_action_buttons {
    position: absolute;
    right: 10px;
    top: 0;

    .dm_button {
      padding: 0;

      .icon {
        color: @dark_alpha40;
        height: 12px;
        width: 12px;

        &:hover {
          color: @primary_blue;
        }
      }
    }
  }

  .select_shifts_checkbox {
    vertical-align: middle;

    span {
      display: none;
    }
  }

  .total-shifts-wrapper {
    color: @dark_alpha80;
    font-weight: 400;
    position: absolute;
    bottom: 5px;
    left: 8px;
    line-height: initial;
  }

  // Targeting InputSearch molecule wrapper
  // TODO: Add a wrapperClassName prop to the component in the DS
  .ds-atom-search-input-container:not(.filter-search) {
    display: flex;
    position: absolute;
    align-items: center;
    left: 10px;
    width: 219px;
    height: 45px;
    margin-top: 13px;

    .ds-atom-search-input-normal {
      box-shadow: none;
      float: left;
      border: none;
      width: calc(100% - 40px);
      background-color: transparent;
    }

    .dm_weekly_shifts_table_search {
      &:hover {
        border: none;
      }
    }

    .ds-atom-search-icon {
      z-index: 0;
      float: left;
      display: inline;
      margin-top: 10px;
    }
  }

  &.today {
    background-color: #f3f3f3;

    &:after {
      content: ".";
      text-align: right;
      vertical-align: middle;
      font-size: 24px;
      position: absolute;
      left: 60px;
      top: -6px;
    }
  }
}

.dm_weekly_shifts_table_head {
  text-align: left;
  padding-left: 9px;
  background-color: #fbfbfb;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.dm_weekly_shifts_table_head:first-child {
  padding-left: 2px;
  background-color: @white;
  border-top: 1px solid @black_alpha10;
  border-bottom: none;
  display: flex;
  align-items: center
}

.dm_weekly_summary_table_cell {
  .ds-text-footnote-regular();
  line-height: 45px;
  border-right: 1px solid @black_alpha10;
  border-top: 1px solid @black_alpha10;

  &:nth-child(4) {
    border-left: 1px solid @black_alpha10;
  }
}

.dm_weekly_summary_table_blank_head {
  width: 90px;
}

.dm_weekly_summary_table_blank_head_title {
  width: 94px;
}

.dm_weekly_summary_table_total_head {
  .ds-text-caption-regular();
  width: 75px;
}

.dm_weekly_summary_table_definition {
  position: relative;
  .ds-text-footnote-bold();
  background-color: @black_alpha05;
  text-align: left;
  padding-left: 10px;
  border-top: 1px solid @black_alpha10;
  border-left: 1px solid @black_alpha10;

  .dm_icon_info_container {
    display: inline-block;
    position: relative;

    .dm_tooltip_info {
      white-space: initial;
      max-width: 300px;
      width: 300px;
      left: -3px;
      top: 25px;
    }
  }

  &.first_cell {
    border-top-left-radius: 6px;
  }

  .dm_icon.info {
    color: @dark_alpha40;
    margin-left: 10px;
    vertical-align: sub;

    &:hover+.dm_tooltip_info {
      display: block;
    }
  }

}

.dm_weekly_summary_table_total {
  .ds-text-footnote-bold();
  background-color: @black_alpha05;
  color: @green;
  border-top: 1px solid @black_alpha10;
}

.weekly-shifts {
  height: calc(100vh - 415px);
  overflow: hidden;
  border-bottom: 1px solid @black_alpha10;
}

.weekly-shifts-row {
  display: flex;

  &__header {
    border-bottom: 1px solid @black_alpha10;
  }

  .workers-sort-by,
  .toggle-all-departments {
    .icon {
      position: relative;
      top: 2px;
    }
  }

  .toggle-all-departments {
    margin-top: 6px;
  }
}

.weekly-shifts-first-row-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.weekly-shifts-cell {
  width: calc((100% - 240px) / 7);
  box-sizing: border-box;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
  }
}

.weekly-department-row {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 20px;
  box-sizing: border-box;
  font-size: 20px;
  border: 1px solid @dark_alpha10;
  border-top: none;
  background-color: #fbfbfb;
  color: @dark_alpha80;

  &:hover {
    cursor: pointer;
    color: @dark;
    background-color: @primary_blue_alpha08;
  }
}

.weekly-placeholder-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  box-sizing: border-box;
  font-size: 20px;
  border: 1px solid @dark_alpha10;
  border-top: none;
  background-color: @white;
  color: @dark_alpha60;
  box-shadow: inset 0px 7px 13px -9px @black_alpha10;

  .icon {
    margin-right: 15px;
    width: 46px;
    height: 46px;

    &.loading-workers {
      color: @primary_blue;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: spin;
      animation-timing-function: linear;
    }
  }

  h3 {
    text-align: center;
  }

  span {
    display: block;
    font-size: 13px;
    margin-top: 8px;
  }
}

.weekly-department-row-chevron {
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  margin-right: 10px;
  transition: transform .2s;

  &.shown {
    transform: rotate(-90deg);
  }
}

.dm_weekly_shifts_table,
.workerCalendarWrapper {
  &.stuck {
    margin-top: 46px;
  }

  .cell_with_check_info {
    width: calc(100% - 20px);
    float: left;
    height: 100%;

    &.with-tooltip {
      padding-left: 0;
      transition: .1s ease-in-out;
    }

    &.for-input-search {
      height: auto;
    }
  }

  .cell_with_check_checkbox {
    width: 15px;
    float: right;

    &.for-shift {
      position: absolute;
      left: 5px;
      top: 9px;

      &.hover {
        left: 9px;
      }
    }
  }

  .cell_selected_checkbox {
    vertical-align: top;
  }

  .header_empty_cell {
    height: 72px;
  }
}

.dm_weekly_shifts_table_header.stuck {
  position: fixed;
  top: 77px;
  width: calc(100% - 122px);
  z-index: 1;
  min-width: 838px;

  th {
    background-color: @white;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .1);

    &.today {
      background-color: #FFF7E9;
    }
  }

  tbody:before {
    content: "";
    display: block;
    height: 40px;
  }

  tbody {
    display: table;
    width: 100%;
  }

  .dm_weekly_shifts_table_head_search_cell {
    min-width: 240px;
  }

  .dm_weekly_shifts_table_worker {
    min-width: 240px;
  }
}

.dm_weekly_shifts_table_cell {
  border-right: 1px solid @black_alpha10;
  border-bottom: 1px solid @black_alpha10;
  padding: 1px;

  &.schedule_cell {
    background: linear-gradient(90deg, #FAFAFA 6px, transparent 1%) center, linear-gradient(#FAFAFA 6px, transparent 1%) center, #E3E3E3;
    background-size: 8px 8px;
  }

  &:first-child {
    border-left: 1px solid @black_alpha10;
    width: 240px;
    box-sizing: border-box;
    flex-direction: column;
  }

  &.today {
    background-color: @yellow_alpha08;
  }
}

.dm_weekly_shifts_table_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.dm_weekly_shifts_table_container {
  height: 100%;
}

.dm_weekly_shifts_table_worker {
  padding: 10px;
  white-space: nowrap;
  box-sizing: border-box;
  height: 100%;

  .dm_weekly_shifts_table_avatar {
    .ds-text-title-base-semibold();
    border-radius: 100%;
    width: 55px;
    height: 55px;
    display: inline-block;
    text-align: center;
    line-height: 55px;
    vertical-align: middle;
    background-color: @dark_alpha40;
  }
}

.dm_weekly_shifts_table_worker_info {
  display: flex;
  margin-left: 8px;
  width: 95%;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .icon {
    height: 12px;
    width: 12px;
    padding-right: 4px;
    vertical-align: middle;
  }
}

.worker-kind-icon {
  height: 12px;
  width: 12px;
  padding-right: 4px;
  vertical-align: middle;

  &.student {
    color: @red;
  }

  &.driver {
    color: @dark;
  }
}

.dm_weekly_shifts_table_worker_name,
.dm_weekly_shifts_table_worker_known_as {
  .ds-text-body-base-semibold();
  line-height: 14px;
  margin-bottom: 5px;
  color: @primary_blue;
  overflow: hidden;
  transition: 0.1s ease-in-out;
  font-size: 11px;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  &.dark {
    color: @dark;
  }
}

.dm_weekly_shifts_table_worker_name button {
  text-align: left;
  padding: 0;
  border: 0;
  background: 0;
  cursor: pointer;
  font-weight: 600;
  color: @primary_blue;
}

.dm_weekly_shifts_table_worker_known_as {
  .ds-text-body-base-regular();
  color: @dark_alpha60;
  font-size: 11px;
}

.dm_weekly_shifts_table_worker_hours_telephone {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dm_weekly_shifts_table_worker_hours {
  .ds-text-footnote-regular();
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  max-width: 155px;
  margin-bottom: 5px;
}

.dm_weekly_shifts_table_worker_telephone_link {
  color: @dark_alpha60;
}

.dm_weekly_shifts_table_worker_telephone {
  .ds-text-footnote-regular();
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: @dark_alpha60;
  max-width: 155px;
  margin-left: 5px;
}

.dm_weekly_shifts_table_allocating_state {
  background-color: @primary_blue_alpha08;
  border: 1px solid transparent;
  box-sizing: border-box;
  color: @dark;
  font-size: 12px;
  height: 77px;
  text-align: center;
  width: 100%;

  .dm_weekly_shifts_table_allocating_state_info {
    padding: 10px 0 10px;
  }

  &:hover {
    background-color: @white;
    border-top: 1px solid @black_alpha10;

    .dm_weekly_shifts_table_allocating_state_info {
      display: none;
    }

    .dm_weekly_shifts_table_shift_buttons {
      display: block;
      border: 1px solid @primary_blue;
      box-sizing: border-box;
      border-radius: 6px;
      margin: 2px;
      padding: 10px;
    }
  }

  .dm_spinner_dots {
    color: @primary_blue;
    height: 20px;
    display: block;
    margin: 10px 0;
    position: relative;
  }

}

.unavailability_hover_mask_container {
  .unavailability_hover_mask_unavailability {
    display: block;
  }

  .unavailability_hover_mask_empty_tile {
    display: none
  }

  &:hover {
    .unavailability_hover_mask_unavailability {
      display: none;
    }

    .unavailability_hover_mask_empty_tile {
      display: block
    }
  }
}

.dm_weekly_shifts_table_shift {
  box-sizing: border-box;
  padding: 10px;
  padding-left: 5px;
  height: 69px;
  margin: 0 auto;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid @black_alpha10;
  position: relative;
  overflow: hidden;

  &.unassigned {
    background: @black_alpha05;
    flex: 1;
  }

  &.pending,
  &.info {
    background: @white;
    border-left: 5px solid @primary_blue_alpha40;
  }

  &.positive {
    background: @white;
    border-left: 5px solid @green;
  }

  &.negative {
    background: @white;
    border-left: 5px solid @red;
  }

  &.proposed {
    background: @white;
    border-left: 5px solid @yellow;
  }

  &.info {
    background: @white;
    border-left: 5px solid @primary_blue;
  }

  &.unavailable {
    .ds-text-caption-regular();
    color: @dark_alpha60;
    border: none;
    background-color: @white;
    line-height: 50px;
    text-align: center;

    &:hover {
      border: 1px solid @dark_alpha60;
      border-left: 1px solid @dark_alpha60;

      .dm_weekly_shifts_table_shift_unavailable {
        display: block;
        cursor: default;
      }
    }
  }

  &.not-active {
    display: flex;
    flex-direction: column;
    background-color: rgb(233, 233, 234);
    border: solid 1px rgba(35, 40, 46, 0.1);
    color: @dark_alpha60;
    text-align: center;
    align-items: center;
    justify-content: center;

    .not-active_title {
      font-size: 14px;
      font-weight: 600;
      color: rgb(35, 40, 46);
      margin-bottom: 3px;
    }

    .not-active_subtitle {
      color: rgb(35, 40, 46);
      font-size: 10px;
    }
  }


  &.leave {
    display: flex;
    flex-direction: column;
    background-color: rgb(253, 242, 242);
    border: solid 1px rgba(35, 40, 46, 0.1);
    color: @dark_alpha60;
    text-align: center;
    align-items: center;
    justify-content: center;

    &.pending {
      background-color: #fff8eb;
    }

    &:hover {
      background-color: rgb(253, 242, 242);
    }

    &.partial {
      height: 35px;
      align-items: flex-start;

      .leave_title {
        margin-bottom: 0;
        flex-direction: row;
        display: flex;
        align-items: baseline;

        .icon {
          padding-bottom: 0;
          margin-right: 6px;
        }
      }
    }

    .leave_title {
      font-size: 14px;
      font-weight: 600;
      color: @red;
      margin-bottom: 3px;
      flex-direction: column;
      display: flex;
      align-items: center;
      width: 100%;

      &.pending {
        color: @yellow;
      }

      .icon {
        height: 9px;
        padding-bottom: 8px;
      }

      span {
        white-space: nowrap;
        max-width: 100%;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        float: left;
      }
    }

    .leave_subtitle {
      color: rgb(35, 40, 46);
      font-size: 10px;

      &.pending {
        color: rgb(35, 40, 46);
      }
    }
  }

  &.unavailability {
    display: flex;
    flex-direction: column;
    background-color: rgb(233, 233, 234);
    border: solid 1px rgba(35, 40, 46, 0.1);
    color: @dark_alpha60;
    text-align: center;
    align-items: center;
    justify-content: center;

    &.partial {
      height: 35px;
      align-items: flex-start;

      .unavailability_title {
        margin-bottom: 1px;
      }
    }

    .unavailability_title {
      font-size: 14px;
      font-weight: 600;
      color: rgb(35, 40, 46);
      margin-bottom: 3px;
    }

    .unavailability_subtitle {
      color: rgb(35, 40, 46);
      font-size: 10px;
    }
  }

  &.empty {
    border: none;
    line-height: 50px;
    text-align: center;
    box-shadow: 0px 0px;

    &:hover {
      border: 1px solid @primary_blue;
      border-left: 1px solid @primary_blue;
    }

    .dm_weekly_shifts_table_shift_button {
      width: 50%;

      @media only screen and (max-width: 1040px) {
        height: 48px;
        width: 100%;
        line-height: inherit;
        margin-top: 0;
      }
    }
  }

  .dm_weekly_shifts_table_shift_info {
    pointer-events: none;

    &.with_check {
      width: 100%;
    }
  }

  &:hover:not(.no_buttons, .unavailable, .leave, .leave_request) {
    background-color: @white;
    border: 1px solid @primary_blue;

    .dm_weekly_shifts_table_shift_info {
      display: none;
    }

    .dm_weekly_shifts_table_shift_buttons {
      display: block;

      &.with_check {
        width: 100%;
      }
    }
  }

  &.no_buttons {
    text-align: left;
    width: calc(100% - 4px);

    &:hover {
      cursor: pointer;
      border: 1px solid @primary_blue;
      background-color: @white;
    }

    &:focus {
      outline: none;
    }

  }

  &.small {
    height: 35px;
    padding: 0;

    .dm_weekly_shifts_table_shift_buttons {
      line-height: 31px;
    }
  }
}

.corner_alert_conflict {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 0;
    border-color: transparent #e55353 transparent transparent;
    right: 0;
    bottom: 0;
    position: absolute;
    border-width: 34px 34px 0px 0;
  }
}

.corner_alert_potential_conflict {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 0;
    border-color: transparent #e55353 transparent transparent;
    right: 0;
    bottom: 0;
    position: absolute;
    border-width: 34px 34px 0px 0;
  }
}

.dm_weekly_shifts_table_shift_hours {
  .ds-text-footnote-semibold();
  display: block;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.with-check-info {
    padding-left: 17px;
  }
}

.dm_weekly_shifts_table_shift_hours_number {
  .dm_weekly_shifts_table_shift_hours();
  .ds-text-footnote-regular();
}

.dm_weekly_shifts_table_shift_count {
  .ds-text-footnote-semibold();
  display: block;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: @primary_blue;
}

.dm_weekly_shifts_table_shift_waypoint {
  margin-bottom: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 95%;
  background-color: transparent;
  min-width: 0;
  color: @white;
}

.dm_weekly_shifts_table_shift_source {
  .ds-text-caption-regular();
  color: @dark_alpha80;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.dm_weekly_shifts_table_shift_source_icon {
  margin-bottom: 2px;
  margin-right: 5px;
  vertical-align: middle;
  width: 8px;
  height: 8px;
}

.dm_weekly_shifts_table_shift_buttons,
.dm_weekly_shifts_table_shift_unavailable {
  white-space: nowrap;
  display: none;
  line-height: 50px;
}

button.dm_weekly_shifts_table_shift_button {
  width: 44%;
  display: inline-block;
  margin-right: 5%;
  margin-left: 18px;
  text-align: center;
  padding: 0;

  @media only screen and (max-width: 1400px) {
    display: block;
    margin: 0;
    margin-left: 23px;
    width: 85%;
    line-height: 20px;
    height: auto;
  }

  &.add {
    margin-right: 0;
    margin-left: 0;

    @media only screen and (max-width: 1400px) {
      margin: 5px 0 0 23px;
    }
  }
}

.dm_weekly_shifts_table_new_button {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: block;
  background-color: @black_alpha20;
  margin: 0 auto 0;
  border: none;

  .dm_icon {
    color: @white;
    width: 14px;
    height: 14px;
    margin: 0 0 1px 0;
  }

  &:hover {
    background-color: @dark_alpha80;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.dm_weekly_modal_daily_table {
  width: 930px;
  table-layout: fixed;
}

.dm_daily_scheduler_shift_container {
  box-sizing: border-box;
  display: block;
  height: 40px;
  padding-top: 10px;
  position: relative;
  width: 100%;
}

.dm_weekly_modal_rounded_overlay {
  border-radius: 5px;
}

.constraint-filter {
  margin: 0px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 @black_alpha05;
  border: solid 1px @black_alpha10;
  display: flex;

  .dm_button {
    padding: 0;
    background-color: @white;
    width: 20px;
    height: 20px;

    .icon {
      color: @dark_alpha40;
      height: 12px;
      width: 12px;
      margin-top: 4px;

      &:hover,
      &.with-constraints {
        color: @primary_blue;
      }
    }
  }
}

.allworkers_checkbox {
  display: flex;
  margin-left: 10px;
}

.workers-filter-selector {
  position: absolute;
  background-color: @white;
  top: 32px;
  min-width: 419px;
  height: 339px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 @black_alpha10;
  border: solid 1px @black_alpha10;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &.centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
  }

  .workers-filter-selector-types {
    width: 209px;
    height: 294px;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: solid 1px @dark_alpha10;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: @dark_alpha60;

    li {
      text-align: left;

      &:hover,
      &.selected {
        background-color: @black_alpha05;
      }

      .workers-filter-selector-type {
        width: 209px;
        height: 35px;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }

        span {
          flex: 1;
          padding-left: 10px;
          margin-right: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .workers-filter-selector-type-count {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          background-color: @black_alpha20;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          font-size: 10px;
          color: @dark;
        }

        .icon {
          width: 11px;
          height: 11px;
          margin-right: 10px;
        }
      }
    }
  }

  .workers-filter-selector-names {
    width: max-content;
    height: 294px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: @dark;

    .ds-atom-search-input-container {
      box-shadow: 0 1px 2px 0 @black_alpha10;
      border: solid 1px @black_alpha10;
      border-radius: 3px;
      margin: 10px;
      height: 35px;
      display: flex;

      input {
        margin-left: 10px;
        width: calc(100% - 50px);
        border: none;
        outline: none;
      }

      .icon {
        margin-left: 10px;
        margin-top: 10px;
      }
    }

    li {
      text-align: left;

      .workers-filter-selector-name {
        height: 35px;
        display: flex;
        align-items: center;

        input {
          margin-left: 10px;
        }

        span {
          margin-left: 10px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .workers-filter-selector-buttons {
    grid-column: 1/-1;
    height: 40px;
    border-top: solid 1px @dark_alpha10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    button {
      border: none;
      background: none;
      color: @primary_blue;
      cursor: pointer;

      &[disabled] {
        color: @dark_alpha60;
        cursor: not-allowed;
      }
    }

    .workers-filter-selector-reset {
      margin-left: 10px;
    }

    .workers-filter-selector-apply {
      margin-right: 10px;
    }
  }
}

.view_mode_selector-button {
  border-radius: 0;

  &:first-child {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }

  &:last-child {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
}

.weekly-leave-tile-container {
  height: 100%;
}

.conflict_alert {
  position: absolute;
  bottom: -24px;
  right: -24px;
  height: 48px;
  width: 48px;
  background-color: @red;
  display: flex;
  align-items: center;
  transform: rotate(45deg);

  .icon {
    transform: rotate(-45deg);
    width: 15px;
    color: white;
    height: 14px;

    path {
      fill: white;
    }
  }
}


.potential_conflict_alert {
  position: absolute;
  bottom: -24px;
  right: -24px;
  height: 48px;
  width: 48px;
  background-color: @yellow;
  display: flex;
  align-items: center;
  transform: rotate(45deg);

  .icon {
    transform: rotate(-45deg);
    width: 15px;
    color: white;
    height: 14px;

    path {
      fill: white;
    }
  }
}

.create-shifts-in-bulk-modal-conflicts {
  display: flex;
  align-items: center;

  .ds-org-modal {
    top: auto;

    .ds-org-modal-image {
      width: auto;
    }

    .ds-org-modal-header {
      padding-bottom: 15px;
    }

    .ds-org-modal-text {
      text-align: initial;
      border-top: 1px solid @dark_alpha10;
      border-bottom: 1px solid @dark_alpha10;
      padding: 15px 0 15px 0;
    }

    .ds-org-modal-footer-button {
      padding: initial;
      white-space: nowrap;
    }
  }
}

.create-shifts-in-bulk-modal-conflicts.leaves-conflicts {
  .ds-org-modal-text {
    text-align: initial;
    padding: 15px 0 15px 0;
    border-top: 0;
    border-bottom: 0;
  }
}

.dm_weekly_stats_container {
  height: 52px;
  padding: 0 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
  border: 1px solid @black_alpha10;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dm_weekly_stats_title {
  font-size: 18px;
}

.dm_weekly_stats {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .separator {
    width: 1px;
    height: 35px;
    margin-left: 15px;
    background-color: @black_alpha10;
  }

  .tag_button {
    line-height: 0px;
    height: 35px;
    border-radius: 20px;
    border: 1px solid @white;
    background-color: @black_alpha05;
    padding: 0 15px 0 15px;

    &:hover {
      cursor: pointer;
      border: 1px solid @black_alpha20;
      background-color: @black_alpha10;
    }
  }

  .dm_weekly_stat {
    color: @dark_alpha60;
    margin-left: 15px;

    strong {
      .ds-text-body-base-semibold;
      color: @dark;
    }

    .icon {
      width: 14px;
      height: 14px;
      display: inline-block;
      margin-right: 8px;
      vertical-align: sub;
      border-radius: 3px;

      &.pending {
        color: @primary_blue;
      }

      &.proposed {
        color: @yellow;
      }

      &.accepted {
        color: @green;
      }

      &.rejected {
        color: @red;
      }

      &.hard {
        color: @red;
      }

      &.soft {
        color: @yellow;
      }

      &:last-child {
        padding-left: 8px;
        margin-right: 0px;
      }

      &.danger {
        path {
          fill: @red;
        }
      }
    }
  }
}

.dm_worker_info_tooltip {
  color: @dark_alpha60;
  position: absolute;
  top: 26px;
  left: 238px;
  background-color: white;
  padding: 16px;
  border: 1px solid @dark_alpha10;
  border-radius: 5px;
  z-index: 1;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
  display: flex;
  gap: 20px;

  &.loading {
    grid-column-gap: 0px;

    .dm_loader_spinner {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -10px;
    top: 15px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: @dark_alpha10;
    border-left: 0px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -9px;
    top: 16px;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-right-color: white;
    border-left: 0px;
  }

  .worker_info_column {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    height: 100%;

    &:last-child {
      border-left: 1px solid @dark_alpha10;
      padding-left: 15px;
      margin-left: 15px;
    }
  }

  .worker_info_notes {
    max-width: 300px;
    text-wrap: balance;
    word-wrap: break-word;
  }

  .worker_info_title {
    .ds-text-footnote-regular();
    color: @dark_alpha80;
    grid-column-start: 1;
    grid-column-end: 3;
    font-weight: 600;
    font-size: 16px;
    margin-top: 5px;
  }

  .dm_worker_student_visa {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .toggle-worker-info-button {
    display: inline-block;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    color: @primary_blue_alpha80;

    &.small {
      font-size: 12px;
      margin-left: 10px;
    }

    &:hover {
      color: @primary_blue;
    }
  }
}

.small-info-table {
  margin-top: 5px;

  th {
    text-align: left;
    font-weight: 600;
  }

  th,
  td {
    border: 1px solid @dark_alpha40;
    padding: 3px 5px;
  }

  &.short {
    width: 400px;
  }

  .success {
    color: @green;
  }

  .warning {
    color: @yellow;
  }

  .error {
    color: @red;
  }

  .right-align {
    font-weight: 600;
    text-align: right;
  }
}

.overflow-table-wrapper {
  position: relative;
  max-height: 400px;
  overflow-y: auto;
  margin-top: 20px;

  thead {
    position: sticky;
    top: 0;
    background-color: @white;
    z-index: 1;
  }
}

.send_comunication_modal {
  .container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;

    .send-communication-textbox {
      border: 0;
      box-shadow: none;
    }

    .message {
      margin: 20px 0;
      resize: none;
      height: 100px;
      width: 400px;
    }

    .result_error {
      color: @red;
      margin: 0px 20px 10px;
      max-width: 400px;
      max-height: 200px;
      overflow-y: scroll;
    }

    .send_com_button {
      align-self: flex-end;
    }

    .result_success {
      color: @green_alpha40;
      margin: 0px 20px 10px;
    }

    .error_display {
      display: flex;
      flex-basis: auto;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      overflow: hidden;

      .worker-with-error {
        margin: 5px 0px 5px 2px;

        &:after {
          content: ", "
        }
      }
    }
  }
}

.header-action-button,
.disabled-rota-button-tooltip {
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
}

.weekly-shifts-hover-table-arrow {
  position: absolute;
  z-index: 2;
  bottom: -1px;
  left: 14px;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -9px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: rgba(35, 40, 46, 0.1);
    border-top: 0px;
    margin-left: -11px;
  }

  &:after {
    left: 50%;
    top: -8px;
    border: 9px solid transparent;
    border-bottom-color: white;
    margin-left: -9px;
    border-top: 0px;
  }
}

.weekly-shifts-hover-table {
  position: absolute;
  top: 55px;
  right: 0;
  z-index: 1;
  background-color: @white;
  border: 1px solid @dark_alpha10;
  box-shadow: 0 2px 4px @black_alpha20;
  padding: 10px;

  table {
    width: 100%;
    color: @dark;
    line-height: initial;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  th {
    white-space: nowrap;
    padding: 0 5px;
    line-height: 27px;
    color: @dark_alpha80;
    border: 1px solid fade(@dark_alpha10, 20%);
    text-align: center;

    &:first-child {
      max-width: 150px;
    }

    &.total {
      background-color: @dark_alpha10;
      color: @dark;
    }

    &.accepted {
      background-color: @green_alpha20;
      color: @green;
    }

    &.proposed {
      background-color: @yellow_alpha20;
      color: @yellow;
    }

    &.pending {
      background-color: @primary_blue_alpha08;
      color: @primary_blue;
    }

    &.rejected {
      background-color: @red_alpha20;
      color: @red;
    }
  }

  td {
    white-space: nowrap;
    padding: 8px;
    text-align: center;
    border: 1px solid fade(@dark_alpha10, 20%);

    &:first-child {
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.show-communications-button {
  position: absolute;
  right: 5px;
  top: 13px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;

  .icon {
    opacity: .4;
    width: 17px;
    height: 14px;
  }

  &:hover .icon {
    opacity: .8;
  }

  &.for-worker-calendar {
    top: 45px;
    right: 9px;
  }
}

.communications-history-list {
  max-height: 500px;
  max-width: 500px;
  overflow-y: auto;
  line-height: normal;

  li {
    margin-bottom: 10px;
    padding: 7px 10px;
    border-radius: 5px;

    &:nth-child(even) {
      background: @black_alpha05;
    }
  }

  h4 {
    font-weight: 600;
    margin-bottom: 5px;
  }

  p {
    color: @dark_alpha80;
  }
}

.show-worker-info-button {
  position: absolute;
  bottom: 13px;
  right: 9px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;

  .icon {
    color: @dark_alpha60;
  }

  &:hover .icon {
    color: @dark;
  }
}

.show-total-shifts-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  margin-right: 5px;
  vertical-align: middle;

  .icon {
    color: @dark_alpha60;
    width: 12px;
    height: 12px;
  }

  &:hover .icon {
    color: @dark;
  }
}

.assign-rota-modal,
.unassign-rota-modal {
  width: 500px;
  line-height: 15px;

  p {
    margin-bottom: 15px;
  }

  .danger {
    color: @red;
    font-weight: 600;
  }
}


.disabled-rota-button-tooltip {
  display: inline-block;
  z-index: 20;

  button.disabled:hover {
    background-color: @black_alpha10;
  }
}

.weekly-filter-mode-dropdown {
  line-height: initial;
  position: absolute;
  left: 38px;
  top: 4px;
  z-index: 1;

  .ds-mol-dropdown {
    margin-top: -15px;
  }

  .ds-atom-drop-down-cell button {
    white-space: nowrap;
    line-height: 25px;
  }
}

.weekly-filter-mode-dropdown-button {
  font-size: 11px;
  background-color: transparent;
  border: none;
  color: @dark_alpha60;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: @dark;
  }

  .icon {
    width: 8px;
    height: 8px;
    margin-left: 5px;
    transform: rotate(90deg);
    vertical-align: middle;
  }
}

.weekly-actions-dropdown {
  .ds-mol-dropdown {
    margin-top: 5px;
    left: auto;
    right: 0;
  }

  .ds-atom-drop-down-cell button[disabled] {
    color: @dark_alpha40;
    cursor: not-allowed
  }

  .with-button .ds-tooltip-container button {
    padding: 8px 10px;
    min-width: 110px;
    width: 100%;
    text-align: left;
    display: block;
    white-space: nowrap;
  }
}

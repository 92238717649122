@import "@jobandtalent/design-system/dist/assets/less/colors";
@import "@jobandtalent/design-system/dist/assets/less/mixins";
@import "scheduler_colors";

a {
  color: @primary_blue;
  text-decoration: none;

  &:hover {
    outline: none;
  }
}

.hidden {
  display: none;
}

.clickable {
  cursor: pointer;
}

.border-box {
  box-sizing: border-box;
}

.dm_ds_action_buttons {
  float: right;
  position: relative;
  height: 37px;

  button.dm_text_button {
    color: @primary_blue;
    background: none;
    border: 0;
    cursor: pointer;
    font: inherit;
    line-height: normal;
    overflow: visible;
    outline: none;
    padding: 0;
    margin-right: 20px;
  }

  .ic-pencil {
    vertical-align: text-top;
    margin-right: 10px;
  }

  .dm_first_button {
    margin-right: 10px;

    button {
      padding-left: 10px;
    }
  }

  .dm_schedules_action_form {
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
    box-sizing: border-box;
  }

  .dm_schedules_action {
    .dm_schedules_action_form();
    padding: 0 10px;

    &:disabled {
      border: 1px solid transparent;
    }

    &.last {
      padding-right: 0;
    }
  }

  .dm_drop_down_link {
    color: @dark;
    line-height: 14px;
    padding: 8px 10px;
    min-width: 110px;
    min-width: 6.875rem;
    width: 100%;
    text-align: left;
    display: block;
    box-sizing: border-box;

    &.disabled {
      color: @dark_alpha40;
      cursor: not-allowed;
    }

    &:focus {
      background-color: @primary_blue_alpha08;
      outline: none;
    }
  }

  .dm_import_demand_input_wrapper {
    box-sizing: border-box;
    display: inline-block;
    position: relative;

    input {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      width: 100%;
    }
  }

  //To remove when import demand in monthly view become part of dropdown
  .dm_import_demand_button_input_wrapper {
    .ds-atom-btn-small-primary-normal();
    .dm_import_demand_input_wrapper();
    padding: 0 22px;
  }

  select {
    vertical-align: top;
    margin-right: 8px;
  }
}

.dm_loader {
  display: none;
  position: relative;

  &.loading {
    display: block;
    position: absolute;
    background: @white;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .dm_loader_content_wrapper {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    &.vertically_centered {
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .dm_loader_text {
    display: block;
  }
}

.dm_small_loader {
  padding: 10px 20px 10px 10px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  color: @primary_blue;
  background: @white;
  border-radius: 2000px;
  box-shadow: 0 1px 10px @black_alpha20;
  font-size: 14px;
  z-index: 10;

  &.loading {
    display: block;
  }
}

.dm_loader_spinner,
.dm_small_loader_spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
  margin-bottom: 10px;
}

.dm_small_loader_spinner {
  vertical-align: middle;
  margin: 0;
  margin-right: 20px;
}

.dm_loader_spinner-item1,
.dm_loader_spinner-item2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: @primary_blue;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  animation: spinner-bounce 2.0s infinite ease-in-out;
}

.dm_loader_spinner-item2 {
  animation-delay: -1.0s;
}

@keyframes spinner-bounce {

  0%,
  100% {
    transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
  }
}

.dm_loader_text {
  .ds-text-body-base-semibold();
  color: @primary_blue;
}

@-moz-keyframes spin {
  to {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(-360deg);
  }
}

.ds-org-table {
  table-layout: fixed;
  border: none;

  .ds-mol-table-row-single {
    border-bottom: 1px solid @black_alpha10;

    &:hover {
      box-shadow: inset 0 -1px 0 0 @primary_blue_alpha40, inset 0 1px 0 0 @primary_blue_alpha40;
      background-color: @primary_blue_alpha08;
    }

    &:last-child {
      border-bottom: none;
    }

    td {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  th,
  td {
    &.super-narrow {
      width: 30px;
    }

    &.narrow {
      width: 60px;
    }

    &.medium {
      width: 80px;
    }

    &.large {
      width: 300px;
    }

    &.small_left_pad {
      padding-left: 10px;
    }

    &.multiline {
      padding-top: (20 / @px);
      padding-bottom: (20 / @px);
      line-height: (20 / @px);
    }

    &.centered {
      text-align: center;
      padding: 0 10px;
    }
  }

  td {
    line-height: (45 / @px);

    &.left_padded {
      padding-left: 47px;
    }

    &.client-name {
      line-height: initial;
      vertical-align: middle;
    }
  }

  th {
    a {
      .ds-text-caption-regular();
      text-transform: uppercase;
      font-weight: 600;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  strong {
    font-weight: 600;

    a {
      color: @dark;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      .ds-text-caption-regular();
      font-size: 12px;
    }
  }

  .right_aligned {
    text-align: right;
  }

  .left_aligned {
    text-align: left;
  }

  .center_aligned {
    text-align: center;
  }

  &.without-shadow {
    box-shadow: none;
  }
}

.ds-mol-dropdown {
  max-height: 50vh;
  overflow-y: auto;

  a.dropdown-item {
    display: block;
    color: @dark;
    padding: (8 / @px) (10 / @px);
  }
}

.small_action_button {
  .ds-atom-btn-tiny-secondary-normal();
  padding: 0 6px;

  &.with_margin {
    margin-left: 3px;
  }
}

.profile_link_button {
  float: right;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.dm_button {
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  color: @white;
  text-transform: uppercase;
  background-color: @header_color;
  border-radius: 8px;

  &.big {
    line-height: 40px;
    padding: 0 10px;
    font-size: 12px;
    display: inline-block;
    text-decoration: none;
  }

  &.dark {
    background-color: @primary_blue;
  }

  &:hover {
    opacity: .8;
  }

  &:focus {
    outline: none;
  }

  &.clear {
    border: none;
    background-color: transparent;
  }
}

.dm_dropdown_option_disabled {
  color: @dark_alpha40;
  cursor: not-allowed;
  pointer-events: none;
}

.dm_dropdown_button {

  &.disabled {
    color: @dark_alpha40;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.dm_pagination_wrapper {
  text-align: center;
}

.pagination {
  .clearfix();
  margin-top: 20px;
  text-align: center;
  color: @primary_blue;
  line-height: 35px;
  border: 1px solid @primary_blue_alpha40;
  border-radius: 3px;
  display: inline-block;
  background-color: @white;

  a,
  em,
  span {
    display: inline-block;
    float: left;
    text-decoration: none;
    padding: 3px 5px;
    color: @primary_blue;
    border-right: 1px solid @primary_blue_alpha40;
    padding: 0 10px;
    min-width: 15px;
    text-align: center;
    font-weight: 600;

    &:last-child {
      border-right: none;
    }
  }

  a:hover {
    cursor: pointer;
    background-color: transparentize(@primary_blue, .9);
  }

  .current {
    background-color: @primary_blue;
    color: @white;
  }

  .disabled {
    color: @dark_alpha40;
  }
}

.dm_icon {
  &.edit {
    width: 13px;
    height: 13px;
    color: @dark_alpha60;

    &:hover {
      color: @primary_blue_alpha80;
    }
  }

  &.calendar {
    width: 15px;
    height: 15px;
    color: @dark_alpha60;

    &:hover {
      color: @primary_blue_alpha80;
    }
  }

  &.scheduled {
    width: 13px;
    height: 15px;
    color: @dark_alpha60;

    &:hover {
      color: @primary_blue_alpha80;
    }
  }

  &.chevron {
    width: 6px;
    height: 10px;
    margin: 0 8px;
    color: @dark_alpha60;

    &.left {
      transform: scaleX(-1);
    }
  }

  &.delete {
    width: 15px;
    height: 15px;
    cursor: pointer;
    color: @dark_alpha40;

    &:hover {
      color: @red;
    }
  }

  &.clock {
    width: 15px;
    height: 15px;
    color: @dark_alpha60;

    &:hover {
      color: @primary_blue_alpha80;
    }
  }

  &.copy {
    width: 19px;
    height: 23px;
    cursor: pointer;
    color: @dark_alpha40;

    &:hover {
      color: @primary_blue_alpha80;
    }
  }

  &.spinner {
    width: 15px;
    height: 15px;
    color: @primary_blue;
  }

  &.graduation-cap {
    width: 15px;
    height: 15px;
    color: @red;
    vertical-align: middle;
    margin-right: 5px;
  }

  &.cross {
    width: 9px;
    height: 9px;
    cursor: pointer;
    color: @dark_alpha40;

    &:hover {
      color: @primary_blue_alpha80;
    }
  }

  &.external {
    width: 15px;
    height: 15px;
    cursor: pointer;
    color: @primary_blue_alpha80;
    vertical-align: sub;

    &:hover {
      color: @primary_blue;
    }
  }

  &.info {
    width: 15px;
    height: 16px;
  }

  &.date {
    width: 15px;
    height: 15px;
    color: @primary_blue;
  }

  &.validity {
    width: 15px;
    height: 15px;
    color: @primary_blue;
  }

  &.document {
    width: 15px;
    height: 15px;
    color: @primary_blue;
  }

  &.people {
    width: 15px;
    height: 15px;
    color: @primary_blue;
  }

  &.plus {
    width: 13px;
    height: 15px;
    color: @dark_alpha80;
    display: inline-block;
    vertical-align: middle;
  }

  &.cog {
    width: 15px;
    height: 15px;
    color: @dark_alpha60;
  }

  &.minus {
    width: 13px;
    height: 3px;
    color: @dark_alpha60;
    display: inline-block;
    vertical-align: middle;
  }

  &.clear {
    width: 15px;
    height: 15px;
    cursor: pointer;
    color: @dark;

    &:hover {
      color: @red;
    }
  }

  &.earth {
    width: 15px;
    height: 15px;
    color: @dark_alpha60;
  }

  &.worker {
    width: 13px;
    height: 13px;
    color: @dark_alpha60;

    &:hover {
      color: @primary_blue_alpha80;
    }
  }

  &.list {
    width: 15px;
    height: 15px;
    color: @dark_alpha60;
  }

  &.collapsible {
    color: @primary_blue;

    &:hover {
      color: @primary_blue;
    }
  }

  &.dates {
    width: 16px;
    height: 16px;
  }

  &.arrow {
    width: 23px;
    height: 17px;
    color: @dark_alpha40;

    &:hover {
      color: @primary_blue_alpha80;
    }
  }
}

.dm_icon_link {
  text-decoration: none;
  margin-left: 20px;
  vertical-align: middle;
  display: inline-block;
  line-height: 15px;

  .dm_icon {
    display: block;
  }

  &.without-margins {
    margin-left: 0;
  }
}

.dm_breadcrumb {
  .clearfix();
  position: relative;
  font-size: 18px;
  padding-bottom: 5px;
  border-bottom: 1px solid @dark_alpha10;
  margin-bottom: 35px;

  a {
    text-decoration: none;
  }
}

.dm_breadcrumb_tabs {
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;

  .ds-atom-tab {
    padding: 0 10px;
  }
}

.dm_breadcrumb_new_data {
  margin-left: 10px;
  padding: 0 10px;
}


.ds-atom-btn-primary-normal,
.ds-atom-btn-small-primary-normal {
  &:before {
    content: "";
    width: 0;
    opacity: 0;
    transition-duration: .2s;
    transition-property: opacity;
  }

  &:after {
    content: "";
    opacity: 0;
    transition-duration: .2s;
    transition-property: opacity;
  }

  &.loading {
    pointer-events: none;
    cursor: not-allowed;
    position: relative;
    color: transparent;

    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      opacity: 1;
    }

    &:after {
      position: absolute;
      left: 50%;
      margin-left: -10px;
      margin-top: 5px;
      width: 15px;
      height: 15px;
      border: 4px solid;
      border-left-color: transparent;
      border-radius: 50%;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: spin;
      animation-timing-function: linear;
      transition-delay: .2s;
      opacity: 1;
      color: @white;
    }
  }
}

.dm_flash {
  border-bottom: 3px solid;
  padding: 10px;
  margin-bottom: 30px;

  &.notice {
    background-color: @primary_blue_alpha08;
    border-color: @primary_blue;
  }

  &.alert,
  &.error {
    background-color: @red_alpha10;
    border-color: @red;
  }
}

.dm-org-blank-slate-img {
  background-image: url(../../images/time_sheet/scene-5.svg);
  margin: 100px auto 15px auto;
  height: 128px;
  width: 155px;
}

.dm-org-blank-slate-cta {
  margin-bottom: 100px;
}


.view_mode_selector {
  display: inline-block;
  margin-right: 10px;

  &-button {
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 @black_alpha05;
    border: solid 1px @dark_alpha10;
    color: @dark;
    font-weight: 400;
    margin: 0 2px 0 0;
    padding: 0px 10px 11px;
    min-width: 66px;

    &.off {
      background-color: @white;
    }

    &.on {
      background-color: @black_alpha05;
      box-shadow: none;
    }
  }
}

.week_selector {
  width: 37px;
  height: 37px;
  margin: 0 2px 0 0;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: @white;

  &.week_selector-previous {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .icon {
      margin: 0 auto;
      display: block;
      transform: rotate(-90deg);
      height: 15px;
      width: 15px;
    }

    &>.dm_header_action {
      border-radius: 0;
    }
  }

  &.week_selector-next {
    margin-left: -8px;
    margin-right: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .icon {
      margin: 0 auto;
      display: block;
      transform: rotate(90deg);
      height: 15px;
      width: 15px;
    }
  }
}

.react-error-container {
  z-index: 1001;
}

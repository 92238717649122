@import "@jobandtalent/design-system/dist/assets/less/colors";

.patterns-rotas-tabs .ds-atom-tab:not(.selected) {
  cursor: pointer;
}

.patterns-rotas-table tbody tr {
  cursor: pointer;
}

.pattern-rota-action-button {
  height: 20px;
  width: 20px;
  color: @dark_alpha60;
  border: none;
  background: none;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 10px;

  .icon {
    height: 20px;
    width: 20px;
  }

  &.delete:hover {
    color: @red;
  }
}

.create-pattern-rota-modal {
  input {
    box-sizing: border-box;
  }
}

.create-pattern-rota-modal-container {
  display: flex;

  &> :first-child {
    width: 400px;
  }

  &.rotas> :first-child {
    width: 20vw;
  }
}

.create-pattern-calendar,
.create-rota-calendar {
  margin-left: 20px;

  table {
    table-layout: fixed;
    width: 450px;
  }

  tbody {
    display: block;
    height: 360px;
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  th {
    width: calc(100% / 7);
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    height: 40px;
  }

  .select-day-button,
  .select-column-button {
    display: block;
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 15px 0;
    cursor: pointer;
    border: 1px solid @white;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;

    &.selected {
      background-color: @primary_blue_alpha08;
    }

    &:hover {
      color: @primary_blue;
      box-shadow: inset 0 0 1px 1px @primary_blue;
    }
  }

  .select-column-button {
    &:hover {
      box-shadow: none;
      cursor: pointer;
    }
  }

  .ds-org-blank-slate {
    margin-top: 40px;
    width: 450px;
  }

  &.disabled {

    .select-day-button,
    .select-column-button {
      pointer-events: none;
      cursor: grab;

      &:hover {
        cursor: grab;
      }
    }

  }
}

.create-rota-calendar {
  width: 60vw;

  .ds-org-blank-slate {
    width: auto;
  }

  table {
    width: auto;
  }

  tr {
    min-height: 46px;
  }

  td {
    position: relative;
    text-align: center;
    vertical-align: top;
  }

  .not-working {
    display: flex;
    border: 1px solid @white;
    border-radius: 3px;
    color: @dark_alpha80;
    background-color: rgba(0, 0, 0, .03);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
  }

  .select-day-button {
    box-shadow: inset 0 0 1px 1px @primary_blue;
    color: @primary_blue;

    &:hover {
      background-color: @primary_blue_alpha08;
    }

    &.with-shifts {
      padding: 0;
      line-height: 21px;
    }
  }
}

.radio-wrapper label:first-child {
  margin-bottom: 10px;
}

.rotas-duration-arrow {
  transform: rotate(90deg);
  vertical-align: middle;
  width: 24px;
}

.clear-filters-button {
  color: @primary_blue_alpha80;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: @primary_blue;
  }
}

.patterns-rotas-actions-column {
  width: 100px;
}

.ongoing-rota-checkbox {
  margin-left: 10px;
  vertical-align: middle;
}

.auto-accept-cb {
  margin-left: 0;
  margin-top: 32px;

  input {
    vertical-align: middle;
  }
}

.copy-to-all-cb {
  margin-left: 0;

  input {
    vertical-align: middle;
  }
}

.rotas-calendar-shift {
  .ds-text-footnote-semibold();

  line-height: 20px;
  border-radius: 3px;
  padding: 0 5px;
  border: 1px solid @white;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  color: @white;
  position: relative;
  padding-right: 20px;

  .edit-shift-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }


  .remove-shift-button {
    display: none;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    position: absolute;
    right: 5px;
    top: -2px;
    color: inherit;

    .icon {
      width: 12px;
    }
  }

  &:hover .remove-shift-button {
    display: block;
  }

  &.big {
    height: 42px;
    padding-right: 5px;
    padding-top: 2px;

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.dm_scheduler_container {
  position: relative;
}

.dm_daily_view_container.dm_sticky_container.stuck {
  padding-top: 108px;
}

.dm_daily_schedule {
  box-sizing: border-box;
  position: relative;
  min-width: 870px;
  min-height: 50vh;

  .dm_daily_schedule_header {
    min-width: 860px;
  }

  .dm_header_actions_container.dm_sticky_header {
    .dm_hide_box_shadow_below_sticky_headers();
    z-index: 3;

    &.stuck {
      box-shadow: none;
      padding: 20px 4px 20px 4px;
      z-index: 4;
    }
  }
}

.dm_daily_scheduler_wrapper {
  margin-top: 20px;
}

.dm_hide_box_shadow_below_sticky_headers {
  // This hides border-shadow of dm_daily_scheduler_body below sticky headers when scroll
  box-sizing: content-box;
  margin-left: -4px;
  margin-right: -4px;
  padding-left: 4px;
  padding-right: 4px;
}

.dm_daily_view_wrapper {

  .dm_daily_scheduler_head {
    min-height: 47px;
    position: relative;

    &.dm_with_left_section {
      .dm_hide_box_shadow_below_sticky_headers();
      width: 100%;

      &.stuck {
        background-color: @white;
        min-width: 838px;
        position: fixed;
        top: 77px;
        width: calc(100% - 122px);
        z-index: 3;
      }

      &:before {
        box-shadow: 0 3px 6px -2px @black_alpha10;
        left: -16px;
        width: calc(100% + 32px);
      }
    }

    .dm_daily_scheduler_head_element {
      padding: 5px 0;
      width: calc((100% - 180px) / 26);

      &.crossing_day {
        line-height: 35px;
        width: 5px;
      }

      &.white_bg {
        width: 170px;
        float: left;
      }
    }
  }

  .dm_daily_scheduler_body {
    border-radius: 10px;
    border: solid 1px @dark_alpha10;
    margin-top: 15px;

    &:hover {
      box-shadow: 0 2px 8px 0 @black_alpha10;

      &.dm_daily_scheduler_body_element {
        background-color: @dark_alpha10;
      }
    }
  }

  .dm_daily_scheduler_add_button {
    bottom: 30px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(191, 191, 191, 0.5);
    height: 60px;
    position: fixed;
    right: 30px;
    width: 60px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba(92, 171, 247, 1);
    }
  }
}

.dm_daily_schedule_sidebar {
  width: 325px;

  .close {
    font-size: 0;
  }
}

.dm_scheduler_view_log {
  position: absolute;
  top: -57px;
  right: 3px;
  cursor: pointer;
  font-weight: 600;
  color: @primary_blue;
  background-color: transparent;
  border: none;

  &:hover {
    text-decoration: underline;
  }
}

.dm_daily_schedule_logs {
  max-height: 100vw;
  overflow-x: auto;
}

.dm_worker_status_day {
  font-size: 10px;
  display: block;
  line-height: 14px;
  margin-bottom: 20px;
  position: relative;

  span {
    background-color: @white;
    display: inline-block;
    position: relative;
    z-index: 1;
    padding-right: 5px;
  }

  &:after {
    content: "";
    height: 1px;
    background-color: @black_alpha10;
    position: absolute;
    left: 0;
    right: 0;
    top: 6px;
  }
}

.dm_worker_status {
  .ds-text-body-base-semibold();
  position: relative;
  padding-left: 40px;
  padding-top: 5px;
  margin-bottom: 20px;

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    border: 5px solid @white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
  }

  &.pending:before {
    background-color: @pending;
  }

  &.proposed:before {
    background-color: @proposed;
  }

  &.accepted:before {
    background-color: @accepted;
  }

  &.rejected:before {
    background-color: @rejected;
  }

  &.neutral:before {
    background-color: @neutral;
  }
}

.dm_worker_status_time {
  .ds-text-footnote-regular();
  display: block;
  margin-top: 5px;
}

.dm_daily_scheduler_table_wrapper {
  .clearfix();
  margin-left: 170px;
  position: relative;
  z-index: 1;
}

.dm_daily_scheduler_waypoint {
  box-sizing: border-box;
  padding: 15px 15px 45px 15px;
  position: relative;
  width: 170px;
  float: left;
  z-index: 2;

  .dm_options_button {
    height: 14px;
    line-height: 14px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      background-color: transparent;
    }

    .ds-mol-dropdown {
      left: 0;
      right: auto;
      top: 1.1rem;
    }

    .dm_button {
      padding: 0;
      opacity: 1;
    }

    .icon {
      color: @dark_alpha40;
      height: 14px;
      width: 4px;

      &:hover {
        color: @primary_blue;
      }
    }
  }
}

.dm_daily_scheduler_waypoint_field {
  padding-bottom: 5px;
  position: sticky;
  top: 142px;
}

.dm_daily_scheduler_waypoint_tag {
  .ds-text-footnote-regular();
  color: @dark_alpha40;
  display: block;
  margin-bottom: 5px;
}

.dm_daily_scheduler_waypoint_name {
  .ds-text-body-base-semibold();
}

.dm_daily_scheduler_waypoint_buttons {
  margin-top: 15px;
  position: relative;
}

.dm_daily_scheduler_waypoint_button {
  position: relative;
  width: 65px;

  &.send_shifts {
    float: right;
  }
}

.dm_daily_scheduler_demand_wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  line-height: 34px;
  text-align: center;
}

.dm_daily_scheduler_demand {
  background-color: @white_alpha60;
  border-radius: 50%;
  padding: 3px;
  height: 24px;
  min-width: 24px;
  width: fit-content;
  display: inline-block;
  line-height: 20px;
  text-align: center;
  color: @white;
  font-size: 14px;
  position: relative;
  z-index: 1;

  &.not_fulfilled {
    color: @red;
  }

  &.pending {
    color: @primary_blue;
  }

  &.fulfilled {
    color: @green;
  }
}

.dm_daily_scheduler_demand_tick {
  pointer-events: none;
  width: 12px;
  height: 10px;
}

.dm_daily_schedule_break_time {
  background: -moz-radial-gradient(rgba(0, 0, 0, .05) 5%, transparent 15%),
    -moz-radial-gradient(rgba(0, 0, 0, .05) 5%, transparent 15%),
    rgba(255, 255, 255, .6);
  background: -webkit-radial-gradient(rgba(0, 0, 0, .05) 5%, transparent 15%),
    -webkit-radial-gradient(rgba(0, 0, 0, .05) 5%, transparent 15%),
    rgba(255, 255, 255, .6);
  background: radial-gradient(rgba(0, 0, 0, .05) 5%, transparent 15%),
    radial-gradient(rgba(0, 0, 0, .05) 5%, transparent 15%),
    rgba(255, 255, 255, .6);
  background-position: 0 0, 5px 5px;
  -webkit-background-size: 10px 10px;
  -moz-background-size: 10px 10px;
  background-size: 10px 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 5;
  cursor: pointer;
}

.starts_yesterday .dm_daily_schedule_break_time {
  cursor: auto;
}

.dm_daily_schedule_break_time_popover {
  padding: 15px;
  position: absolute;
  background-color: @white;
  border: 1px solid @dark_alpha40;
  border-radius: 5px;
  line-height: initial;
  font-weight: 400;
  color: @dark_alpha80;
  white-space: nowrap;
  z-index: 20;

  strong {
    color: @dark;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
  }

  &:after,
  &:before {
    bottom: 100%;
    left: 15px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
  }

  &:after {
    border-color: transparent;
    border-bottom-color: @white;
    border-width: 5px;
    margin-left: -5px;
  }

  &:before {
    border-color: transparent;
    border-bottom-color: @dark_alpha40;
    border-width: 6px;
    margin-left: -6px;
  }
}

.dm_daily_scheduler_shift_text {
  position: relative;
  z-index: 10;
}

.dm_daily_schedule_has_break_cb,
.dm_daily_schedule_auto_accept {
  .ds-text-body-base-semibold();
  text-align: right;
  display: inline-block;
  margin-bottom: 15px;
  cursor: pointer;

  input {
    margin-right: 5px;
    vertical-align: middle;
  }
}

.dm_daily_schedule_auto_accept {
  text-align: left;
  margin-left: calc(50% + 5px);
}

.dm_day_selector_container {
  width: fit-content;

  .dm_day_selector_day {
    color: @dark;
    display: block;
    line-height: 20px;
    padding-top: 5px;
    text-transform: uppercase;
  }

  .dm_day_selector_day_info {
    font-size: 20px;
    font-weight: 600;
    vertical-align: middle;
  }

  .dm_day_selector_icon {
    pointer-events: none;
    height: 12px;
    margin-bottom: 3px;
    vertical-align: middle;
    width: 7px;
  }

  .dm_day_selector_info {
    color: @dark;
    display: block;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    text-align: center;
    width: 100%;
  }
}

.workerCalendarWrapper {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-bottom: 1px solid @black_alpha10;

  .weekDay {
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 600;
    position: sticky;
    top: 75px;
    background-color: @white;
    border-bottom: 1px solid @black_alpha10;
    z-index: 1;
  }

  .day {
    position: relative;
    display: flex;
    flex-direction: column;
    border-top: 1px solid @black_alpha10;
    border-right: 1px solid @black_alpha10;
    min-height: calc(70px + 2rem);

    &:nth-child(7n + 1) {
      border-left: 1px solid @black_alpha10;
    }

    h2 {
      border-bottom: 1px solid @black_alpha05;
      line-height: 2rem;
      font-size: 1rem;
      margin-bottom: 0;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;

      span {
        opacity: .5;
        margin-left: 5px;
        font-size: 0.8rem;
        text-transform: uppercase;
      }
    }

    &.bottomBorder {
      border-bottom: 1px solid @black_alpha20;
    }

    &.leftBorder {
      border-left: 1px solid @black_alpha20;
    }

    &.today {
      border: 1px solid @primary_blue;

      h2 {
        background-color: @primary_blue_alpha08;
        border-color: @primary_blue_alpha40;
      }
    }

    &.outOfRange {
      opacity: .4;
      transition: all 0.1s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  .oddMonth {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .dm_weekly_shifts_table_shift {
    margin: 5px 3px;
  }
}

.dm_container .workerCalendarHeader {
  margin-bottom: 10px;

  h1 {
    margin-bottom: 0;
    position: relative;
  }
}

.workerCalendarHeader {
  display: inline-block;
  position: relative;
  line-height: normal;
  display: flex;
  justify-content: space-between;

  .showWorkerInfoButton {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    width: 1.3rem;
    height: 1.3rem;
    line-height: 1.3rem;
    margin-left: 1rem;

    .icon {
      width: 1.3rem;
      height: 1.3rem;
      color: @dark_alpha60;
    }

    &:hover .icon {
      color: @dark;
    }
  }

  .dm_worker_info_tooltip {
    font-size: 14px;
    left: calc(100% + 15px);
    top: -7px;
    width: max-content;
    z-index: 3;
  }
}

.workerCalendarSubheader {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: white;
  padding: 20px 0;
  z-index: 2;
  border-top: 1px solid @black_alpha10;
}

.workerCalendarFilters {
  .DayPickerInput {
    width: 97px;

    input {
      height: 37px;
      vertical-align: middle;
    }

    &.last {
      margin-right: 10px;
    }
  }
}

.workerCalendarRangeArrow {
  transform: rotate(90deg);
  vertical-align: middle;
  width: 24px;
}

.workerCalendarActions {
  display: flex;
  align-items: center;
  gap: 10px;

  .selectedShifts {
    .ds-text-footnote-regular();
  }

  .ds-mol-dropdown {
    left: auto;
    right: 0;
    margin-top: 5px;
  }

  .ds-atom-drop-down-cell button[disabled] {
    color: @dark_alpha40;
    cursor: not-allowed
  }
}

.unselect-shifts-button {
  color: @primary_blue;
  background: none;
  border: 0;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
}

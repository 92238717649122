.ds-org-table tbody tr {
  &.dm_service_area_row {
    display: none;
    border-bottom: none;

    td:first-child {
      padding-left: 60px;
    }
  }

  &.waypoints_shown {
    border-bottom: none;

    .dm_show_waypoints_icon {
      transform: rotate(90deg);
    }
  }

  &.last_waypoint {
    border-bottom: 1px solid @black_alpha10;
  }
}

.dm_show_waypoints_icon {
  use {
    color: @dark;
  }
}

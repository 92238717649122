.dm_weekly_availability_action_buttons {
  margin-bottom: 38px;

  .dm_info {
    display: inline-block;
    line-height: 35px;

    .dm_info_item {
      display: inline-block;
      margin-right: 30px;

      .dm_info_square {
        border-radius: 3px;
        display: inline-block;
        height: 14px;
        margin-right: 15px;
        vertical-align: middle;
        width: 14px;

        &.no-available {
          background-color: @red;
        }

        &.available {
          background-color: @green;
        }
      }

      .dm_info_text {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .dm_weekly_availability_buttons_container {
    float: right;

    .dm_weekly_availability_buttons {
      display: inline-block;

      &:last-child {
        padding-left: 20px;
      }
    }

    .dm_enable_buttons {
      border-right: 1px solid @black_alpha20;
      padding-right: 20px;
    }

    .dm_weekly-availability_button {
      min-width: 100px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

.dm_availability_calendar {
  th:first-child {
    border-right: none;
    padding-left: 0;
    text-align: center;
  }

  tbody {
    tr:hover {
      box-shadow: none;
      background-color: transparent;
    }

    .calendar_cell {
      border-left: none;
      border-collapse: collapse;
      cursor: pointer;
      font-size: 12px;
      line-height: 38px;
      padding: 0;
      text-align: center;
      user-select: none;
      white-space: nowrap;

      &.available {
        background-color: @green_alpha08;
        border: 1px solid @black_alpha10;
        color: @green;
      }

      &.no-available {
        background-color: @red_alpha08;
        border: 1px solid @black_alpha10;
        color: @red;
      }

      .dm_workload_button_not_visible {
        color: @dark;
        line-height: 38px;
        width: 100%;
      }
    }
  }
}

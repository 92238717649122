@import "base/normalize";
@import "base/reset";
@import "@jobandtalent/design-system/dist/assets/less/mixins";
@import "@jobandtalent/design-system/dist/assets/less/colors";
@import "@jobandtalent/design-system/dist/assets/less/fonts";
@import "@jobandtalent/design-system/dist/assets/less/organisms";
@import "@jobandtalent/design-system/dist/assets/less/molecules";
@import "@jobandtalent/design-system/dist/assets/less/atoms";
@import "@jobandtalent/design-system/dist/styles";
@import "base/scheduler_colors";
@import "base/general";
@import "styles/layout";
@import "styles/forms";
@import "styles/daily_schedule";
@import "styles/authentication";
@import "styles/imports";
@import "styles/service_areas";
@import "styles/admin/scenarios";
@import "styles/admin/workers";
@import "styles/admin/actions";
@import "styles/sidebar";
@import "styles/weekly_availability";
@import "styles/attendance";
@import "styles/weekly_schedule";
@import "styles/worker_calendar";
@import "styles/clients";
@import "styles/workload";
@import "styles/client_demand";
@import "styles/patterns_rotas";
@import "styles/leave";
@import "styles/shared";

@import "choices.js/public/assets/styles/choices.min.css";
@import "vendor/choices";
@import "vendor/pikaday";

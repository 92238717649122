h1.dm_scenarios_header {
  margin-bottom: 15px;
}

.dm_scenarios_explanation {
  margin-bottom: 40px;
  opacity: .7;
}

.dm_scenarios {
  margin-bottom: 40px;
}

.dm_scenarios_element {
  .clearfix();
  margin-bottom: 20px;
  border-bottom: 1px solid @gray_separator;
  padding-bottom: 20px;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.dm_scenario_container {
  float: left;

  h3 {
    .ds-text-subtitle-big-bold();
    margin-bottom: 15px;
  }
}

.dm_scenario_explanation {
  margin-top: 35px;
  margin-left: 250px;
  line-height: 20px;

  code, pre {
    color: @dark_alpha80;
    white-space: pre-line;
    font-family: monospace;
    background: @dark_alpha10;
    display: inline-block;
    padding: 0 4px;
    border-radius: 3px;
  }

  pre {
    display: block;
    border-radius: 3px;
    padding: 5px 10px;
    margin: 5px 0 20px;
    overflow-x: scroll;
  }
}

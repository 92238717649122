.admin-actions {
  li {
    overflow-x: auto;
  }
}

.dm_actions_search_form_label {
  position: absolute;
  top: -25px;
  white-space: nowrap;
  right: 0;
  color: @dark_alpha60;
}

.dm_actions_content_toggler {
  color: @dark;

  &:hover {
    text-decoration: underline;
  }

  &.content_shown {
    .dm_icon {
      transform: rotate(90deg);
    }
  }
}

.dm_action_chip {
  .ds-atom-chip();

  &.succeeded {
    .positive();
  }

  &.failed,
  &.crashed {
    .negative();
  }

  &.waiting_to_run,
  &.running {
    .warning();
  }
}

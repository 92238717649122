.choices {
  margin-bottom: 0;

  .choices__list--dropdown {
    z-index: 10;
    border: 1px solid rgba(@dark, 0.1);
  }

  .choices__list--multiple .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: rgba(@primary_blue, 0.2);
    border: 1px solid rgba(@primary_blue, 0.2);
    color: @primary_blue;
    word-break: break-all;
    box-sizing: border-box;
    padding-right: 5px;
  }

  .choices__input {
    background-color: transparent;
  }

  .choices__inner {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: @white;
    padding: 7.5px 7.5px 3.75px;
    border: 1px solid rgba(@dark, 0.1);
    border-radius: 3px;
    font-size: 14px;
    min-height: 44px;
    overflow: hidden;
  }

  &[data-type*=select-multiple] .choices__button {
    border-left: 1px solid rgba(@primary_blue, 0.4);
  }

  &.is-disabled {
    .choices__inner {
      background-color: @white;
    }

    .choices__list--multiple .choices__item {
      background-color: rgba(@dark, 0.2);
      border: 1px solid rgba(@dark, 0.2);
      color: rgba(@dark, 0.8);
      opacity: 0.8;
      padding-right: 10px;
    }

    .choices__input {
      background-color: transparent;
    }

    .choices__button {
      display: none;
    }

    &[data-type*=select-multiple] .choices__button {
      display: none;
    }
  }

  &[data-type*=select-multiple] .choices__button {
    margin: 0 0 0 6px;
  }
}

.leave-modal {
  input {
    box-sizing: border-box;
  }

  .ds-atom-radio {
    justify-content: space-between;
    margin-right: 10px;
  }

  .leave_input_field_wrapper {
    display: block;

    &.multiple_children {
      display: flex;
      justify-content: space-between;
    }

    &.two_columns {
      display: flex;
      justify-content: inital
    }
  }

  .ds-mol-input-field {
    margin-bottom: (20 / @px);
  }

  .leave_form_input_explanation {
    color: @dark_alpha60;
    font-size: (12 / @px);
    font-weight: normal;
    margin-left: 5px;
  }

  .leave_form_notes_textarea {
    border-color: @dark_alpha10;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 @black_alpha05;
    box-sizing: border-box;
    margin-top: (5 / @px);
    padding: (10 / @px);
    outline-color: @dark_alpha10;
    resize: none;
    width: 100%;

    &:focus {
      border-color: @primary_blue;
      outline: none;
    }

    &::placeholder {
      font-style: italic;
      opacity: .6;
    }
  }
}

.leave_tags_select {
  .Select-value {
    .Select-value-icon {
      padding: 1px 5px 0px;
      font-size: 15px;
    }
  }

  .Select-input {
    input {
      padding: 10px 0 10px;
    }
  }
}

.leaves_switch {
  margin-right: 0.625rem;
  vertical-align: middle;
}

.leaves_switch_text {
  vertical-align: middle;
}

.DayPickerInput .full-width {
  width: 100%;
}

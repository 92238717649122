@attendance_pending: #334E70;
@attendance_confirmed: #6BCBA4;
@attendance_late: #A0586B;
@attendance_overtime: #F68989;
@attendance_notified: #BDAC94;
@attendance_not_notified: #EEBB57;

.dm_attendance_numbers {
  padding: 15px;
  margin: 0 30px 20px;
  border: 1px solid @black_alpha10;
  background-color: @black_alpha05;
  border-radius:6px;
  display: flex;
  justify-content: space-between;
}

.dm_attendance_number {
  color: @dark_alpha60;

  strong {
    .ds-text-body-base-semibold();
    color: @dark;
  }
}

.dm_attendance_number_color {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: sub;
  border-radius: 3px;
  background-color: @dark;

  &.pending {
    background-color: @attendance_pending;
  }

  &.confirmed {
    background-color: @attendance_confirmed;
  }

  &.late {
    background-color: @attendance_late;
  }

  &.overtime {
    background-color: @attendance_overtime;
  }

  &.notified {
    background-color: @attendance_notified;
  }

  &.not_notified {
    background-color: @attendance_not_notified;
  }
}

.dm_attendance_table {
  color: @dark_alpha80;

  thead {
    border-bottom: 1px solid @black_alpha10;
  }

  tbody {
    td.dm-attendance-table-cell-select-container {
      display: flex;
      justify-content: flex-end;
      overflow: initial;
      vertical-align: middle;
    }

    tr.confirmed {
      background-color: fade(@green, 6%);
    }

    tr.discarded {
      background-color: fade(@red, 8%);
    }
  }

  .confirmed {
    background-color: @green_alpha08;
  }

  td.dm_attendance_punch_row {
    overflow: inherit;
  }

  .ds-org-blank-slate {
    min-height: 60vh;
  }

  .dm_attendance_empty_state_link {
    display: inline-block;
  }

  .dm_attendance_empty_state_button {

    &.first_child {
      margin-right: 10px;
    }
  }
}

.dm_attendance_select_container{
  position: relative;
  width: 100%;

  .Select__container__option {
    text-align: right;
  }

  .Select__container__single-value {
    padding-left: 25px;
  }

  .dm_icon {
    color: @red;
    left: 13px;
    top: 13px;
    position: absolute;
    height: 9px;
    width: 9px;
    z-index: 1;

    &.icon-validity {
      color: @green;
      height: 15px;
      width: 15px;
      left: 10px;
      top: 10px;
    }
  }
}

.dm_attendance_confirm_row {
  margin-left: 5px;
  vertical-align: middle;
  font-size: 14px;
  cursor: pointer;

  input {
    margin-right: 7px;
    vertical-align: baseline;

    &:disabled {
      cursor: not-allowed;
      opacity: .4;
    }
  }
}

.ds_attendance_status {
  .ds-text-body-base-regular();
  line-height: 35px;
  width: 155px;
  text-align: left;

  .dm_icon {
    vertical-align: sub;
  }
}

input.dm_attendance_time_input {
  padding-right: 5px;
  text-align: right;
  vertical-align: middle;
  width: 60px;

  &.rest {
    border: none;
    background-color: transparent;
    box-shadow: none;
    font-size: 16px;

    &:hover {
      border: none;
    }

    &::placeholder {
      color: @dark;
      font-size: 16px;
      font-style: normal;
    }
  }

  &.disabled{
    opacity: .3;
    cursor: not-allowed;
  }
}

.dm_attendance_time_shift {
  .ds-text-caption-semibold();
  margin-left: 5px;
  vertical-align: middle;

  &.early {
    color: @green;
  }

  &.late {
    color: @red;
  }
}

.dm-attendance-avatar-field {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ds-atom-avatar-medium.dm-attendance-avatar {
  .ds-text-body-base-semibold();
  background-color: @dark_alpha40;
  box-sizing: content-box;
  display: inline-block;
  font-size: 14px;
  line-height: 40px;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  height: 40px;
  width: 40px;
}

.dm_filter_date_picker {
  line-height: initial;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  input {
    border: none;
    color: transparent important;
    -webkit-text-fill-color: transparent;
    background: transparent;

    &:hover {
      border: none;
    }
  }
}

.dm_date_picker {
  background: @white;
  box-shadow: 0 2px 5px @black_alpha20;
  line-height: 16px;
  position: absolute;
  z-index: 20;
  left: 1px;
  top: 38px;

  &.weekly {
    .DayPicker-Day.DayPicker-Day--selectedRange.DayPicker-Day--selected{
      background-color: @primary_blue_alpha08;
      border-radius: 0;
      color: @dark;
    }
    .DayPicker-Day--hoverRange {
      background-color: @primary_blue_alpha08;
      border-radius: 0;
    }
  }
}

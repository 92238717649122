.Totals {display: none;}

html {
  height: 100%;
}

body {
  .ds-text-body-base-regular();
  background-color: @bg_grey;
  height: 100%;
}

.dm_header {
  .clearfix();
  box-sizing: border-box;
  box-shadow: 0 2px 4px @black_alpha10;
  background-color: @header_color;
  height: 60px;
  padding-right: 40px;
  line-height: 60px;
  position: relative;
  min-width: 980px;

  &.cable_connected {
    &:after {
      bottom: 0;
    }
  }

  &.cable_disconnected {
    &:after {
      content: "Oops, no internet connection. Changes will not be saved. Try to refresh.";
      left: 0;
      bottom: -20px;
      background-color: @red;
      box-sizing: border-box;
      color: @white;
      display: block;
      height: 20px;
      font-size: 12px;
      position: absolute;
      padding-top: 10px;
      position: absolute;
      text-align: center;
      width: 100%;
      z-index: -1;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }
  }
}

.dm_header_logo {
  float: left;

  img {
    display: block;
  }
}

.dm_header_form_wrapper {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 35%;

  @media only screen and (max-width: 1300px) {
    margin: 0 20%;
    padding: 0;
    text-align: left;
    width: 300px;
  }
}

.dm_header_form {
  display: inline-block;
  width: 100%;
  max-width: 500px;
  position: relative;

  &:hover .dm_header_search_clear {
    display: block;
  }
}

.dm_header_search_input {
  border: none;
  line-height: 35px;
  background: @white;
  box-shadow: 0 1px 2px 0 @black_alpha05;
  border-radius: 4px;
  width: 100%;
  padding: 0 15px;
}

.dm_header_search_submit {
  display: none;
}

.dm_header_search_clear {
  display: none;
  position: absolute;
  right: 10px;
  top: 25px;

  .dm_icon {
    display: block;
  }
}

.dm_header_search_clear_input {
  display: none;
}

.dm_header_links {
  float: right;
}

.dm_header_link_form {
  display: inline-block;
  position: relative;

  &:before {
    content: "";
    background-color: @white_alpha60;
    width: 1px;
    height: 16px;
    position: absolute;
    left: 16px;
    top: 22px;
    display: block;
    position: absolute;
  }
}

.dm_header_link,
.dm_header_link_form button {
  .ds-text-body-base-semibold();
  color: @white;
  text-decoration: none;
  opacity: .6;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  margin-left: 30px;
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  padding-right: 0;

  &:hover {
    opacity: 1;
  }
}

.dm_wrapper {
  .clearfix();
  box-sizing: border-box;
  padding: 40px;
  min-height: calc(100% - 60px); // 57: header height
  min-width: 980px;
}

.dm_page_title {
  .ds-text-subtitle-base-regular();
  .clearfix();
  margin-bottom: 10px;
  position: relative;
}

.dm_page_subtitle {
  font-size: 12px;
  opacity: .6;
}

.dm_page_title_actions {
  position: absolute;
  top: 0;
  right: 0;
}

.dm_container {
  min-height: 90vh; //Fixes jumping in scroll when has sticky header and passes from position relative to sticky
  position: relative;
  background: @white;
  border: 1px solid @black_alpha10;
  box-shadow: 0 2px 4px 0 @black_alpha05;
  border-radius: 6px;
  padding: 20px;

  h1 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: bold;
  }

  &.not_padded {
    padding: 0;
  }

  &.limited {
    min-width: 1220px;
    min-height: 0;
  }
}

.ds-atom-text-input-normal {
  line-height: 35px;
  box-sizing: border-box;
  color: @dark;

  &.for_copy {
    padding-right: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.textarea {
    height: 100px;
  }

  &[disabled],
  &.disabled {
    color: @black_alpha20;
    -webkit-text-fill-color: @black_alpha20;
    font-style: italic;
  }

  &.numeric {
    padding: 0;
    position: relative;

    input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 30px;
      right: 30px;
      border: none;
      background-color: transparent;
      width: calc(100% - 63px);
      padding: 0 10px;
      box-sizing: border-box;
      margin-top: 0;

      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}

.ds-atom-select-input-normal {
  width: auto;
  color: @dark;
}

.dm_input_field_wrapper {

  .Select-input {
    padding: 0;
  }

  .clearfix();
  position: relative;

  &.with_margin {
    margin-bottom: 20px;
  }

  &.with_big_margin {
    margin-bottom: 30px;
  }

  .dm_text_button {
    border: 0;
    background: none;
    color: @primary_blue;
    cursor: pointer;
  }
}

.ds-mol-input-field {
  position: relative;
  height: auto;
  margin-bottom: 20px;

  label {
    white-space: nowrap;

    &.for_checkbox,
    &.for_radio {
      cursor: pointer;
      font-weight: 400;
      display: inline-block;
      margin-right: 20px;

      input {
        margin-right: 7px;
      }
    }

    &.with_margin {
      display: block;
      margin-bottom: 20px;
    }

    &.disabled {
      opacity: 0.4;
    }
  }

  &.two_columns {
    width: calc(50% - 5px);
    margin-right: 10px;
    float: left;

    &.last,
    &:last-child {
      margin-right: 0;
    }
  }

  &.three_columns {
    width: calc(33% - 6px);
    margin-right: 10px;
    float: left;

    &.last,
    &:last-child {
      margin-right: 0;
    }
  }

  &.four_columns {
    width: calc(25% - 10px);
    margin-right: 10px;
    float: left;

    &.last,
    &:last-child {
      margin-right: 0;
    }

    &.fluid {
      width: 20%;
      margin-right: 5%;
    }
  }

  &.third_column {
    width: calc(75% - 2px);
    float: left;

    &.last,
    &:last-child {
      margin-right: 0;
    }
  }

  textarea {
    margin-top: 5px;
    padding-top: 9px;
    height: 50px;
    line-height: 17px;
  }

  span.error {
    margin-bottom: 10px;
  }
}

.dm_switch_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dm_label_explanation {
  .ds-text-caption-regular();
}

.dm_input_field_copy {
  position: absolute;
  right: 7px;
  top: 24px;
}

.dm_input_numeric_changer {
  background-color: #fbfbfb;
  width: 30px;
  display: inline-block;
  line-height: 33px;
  vertical-align: super;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #f6f6f6;
  }

  &.increment {
    border-left: 1px solid @black_alpha05;
    float: right;
  }

  &.decrement {
    border-right: 1px solid @black_alpha05;
  }
}

.dm_input_field_side_link {
  .clearfix();
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 0;

  &.dm_add_new {
    background: none;
    border: none;
    bottom: 20px;
    color: @primary_blue;
    cursor: pointer;
    text-align: end;
    top: inherit;
    &.disabled {
      color: @primary_blue_alpha40;
      cursor: not-allowed;
    }
    &:focus {
      outline: none;
    }
  }
}

.ds-mol-input-field .dm_input_field_side_cb {
  .ds-text-footnote-regular();
  float: right;

  input {
    margin-top: 0;
    margin-right: 5px;

    &:hover {
      cursor: pointer;
    }
  }
}

.dm_file_input_wrapper {
  cursor: pointer;
  position: relative;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  span {
    cursor: pointer;
  }

  input {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
  }
}

.dm_delete_link {
  cursor: pointer;
  background: none;
  border: none;
  color: @red;
  cursor: pointer;
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 0;
  text-decoration: none;
  z-index: 2;

  &:focus {
    outline: none;
  }
}

.worker_unavailabilities_entry {
  margin-bottom: 20px;
  position: relative;

  .worker_unavailabilities_fields {
    margin-bottom: 12px;

    .dm_unavailability_field {
      margin-bottom: 12px;
      z-index: 1;
    }

    .title {
      display: block;
      font-weight: 600;
    }

    .input_date, .time-input-field {
      display: inline-block;
      vertical-align: top;
      width: calc(50% - 4px);
    }

    .time-input-field {
      margin-left: 6px;
      margin-top: 5px;

      &.is-disabled > .Select-control {
        cursor: not-allowed;
        background-color: @white;

        .Select-value {
          color: @dark_alpha60;
          font-style: italic;
          -webkit-text-fill-color: @dark_alpha60;
        }
      }

      &.is-open > .Select-control {
        border: solid 0.0625rem @primary_blue;
      }

      .Select-control {
        padding-right: 0;

        &:focus {
          outline: none;
        }

        .Select-value{
          color:@dark;
          -webkit-text-fill-color: @dark;
        }

        .Select-placeholder {
          color: @dark_alpha60;
          font-size: 14px;
          font-style: italic;
          -webkit-text-fill-color: @dark_alpha60;
        }

        .Select-input {
          & > input {
            margin: 0;
          }
        }
      }
    }

    .unavailability_footer {
      display: inline-block;
      margin-bottom: 0;

      .allDayCheckbox {
        margin-right: 7px;
      }
    }
  }
}

.verify_error_message {
  color: @red;
}

.verify_success_message {
  color: @green;
}

.input_date.rigth{
  .DayPickerInput-Overlay{
    transform:translateX(-165px);
  }
}

.dm_input_field_half {

  .dm_number_of_shifts_error.error{
    float: left;
  }

  .dm_half_input {
    width: 49%;
    display: block;
  }
}

.dm_numeric_no_arrows{

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.dm_number_of_shifts_label{

  .dm_tooltip_info {
    left: 100px;
    top: 27px;
    white-space: normal;
    width: 270px;
  }
}

.label-with-margin {
  display: block;
  margin-bottom: 5px;
}

.clients-form {
  box-sizing: border-box;
  width: 520px;

  * {
    box-sizing: border-box;
  }

  .ds-mol-input-field {
    input {
      margin-top: 0;
    }

    label {
      display: inline-block;
      margin-bottom: 5px;
    }

    &:not(.two_columns):first-child {
      margin-top: 20px;
    }
  }
}

.shifts_by_worker_select_table {
  th, td {
    width: 5px;
    padding: 0px;
    text-align: center;
  }

  tr {
    height: 20px;
  }

  th {
    background: white;
    position: sticky;
    top: -1px;
  }

  .dm_weekly_day_selector_button {
    cursor: pointer;
  }

  button.dm_text_button {
    color: @primary_blue;
    background: none;
    border: 0;
    cursor: pointer;
    font: inherit;
    line-height: normal;
    line-height: 14px;
    cursor: pointer;
    outline: none;
    padding: 0;
    width: 95%;
    text-align: left;

    span {
      overflow: hidden;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
      height: 17px;
    }
  }
}

.color-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.color-field-wrapper {
  box-sizing: border-box;
  position: relative;
  background-color: @white;
  border: solid 1px @dark_alpha10;
  border-radius: 3px;
  width: 45px;
  height: 30px;
  display: flex;
  padding: 3px;

  &:hover {
    border-color: @black_alpha20;
    cursor: pointer;
  }

  .color-field-swatch {
    box-sizing: border-box;
    width: 100%;
    border-radius: 3px;
    border: 1px solid @black_alpha20;

    &.empty {
      background: repeating-conic-gradient(#d6d6d6 0% 25%, transparent 0% 50%) 50% / 10px 10px
    }
  }

  input {
    position: absolute;
    opacity: 0;
    width: 45px;
    height: 30px;
    left: 0;
    top: 0;
    margin: 0;
  }
}

.reset-color-button {
  background: none;
  border: none;
  color: @primary_blue;
  cursor: pointer;
  text-decoration: none;

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}

.dm_authentication_container {
  width: 460px;
  margin: 5% auto 0;
}

.dm_authentication_logo {
  display: block;
  margin: 0 auto;
}

.dm_authentication_backoffice {
  position: absolute;
  bottom: 60px;
  left: 50%;
  margin-left: -63px;
}

.dm_authentication_heading {
  .ds-text-big-light();
  text-align: center;
  margin-bottom: 10px;
  font-size: 34px;
}

.dm_authentication_subheading {
  .ds-text-subtitle-base-regular();
  text-align: center;
  margin-bottom: 40px;
  opacity: .6;
  color: @dark_alpha60;
}

.dm_authentication_google_signin_button {
  background-color: @primary_blue;
  color: @white;
  border-radius: 10px 6px 6px 10px;
  padding-left: 50px;
  font-size: 16px;
  text-align: center;
  line-height: 50px;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.15);
  position: relative;
  width: 260px;
  margin: 0 auto;
  display: block;
  text-decoration: none;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: @white url("../../images/google_g_logo.svg") no-repeat center center;
    width: 50px;
    height: 50px;
    border-radius: 6px 0 0 6px;
  }

  &:hover {
    background-color: darken(@primary_blue, 5);
  }
}

.dm_workers_search_form {
  display: inline;
  font-size: 14px;
  vertical-align: middle;
  margin-left: 50px;
}

.dm_workers_search_form_input {
  line-height: 38px;
  background: @white;
  border: 1px solid @black_alpha10;
  box-shadow: inset 0 2px 0 0 @black_alpha05;
  border-radius: 3px;
  padding: 0 15px;
  margin-right: 10px;
}

.dm_workers_table{

  .primary_cell {
    width: 15%;
  }

  .secondary_cell {
    width: 10%;
  }
}
